<template>
  <div class="container-fluid py-4">
    <div class="row">
      <!-- First Table -->
      <div class="col-12">
        <div class="card my-4">
          <div class="p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <div class="row mb-4 mb-md-0">
                <div class="col-md-8 me-auto my-auto text-left">
                  <h6 class="text-white ps-3">Page d'accueil</h6>
                </div>

                <div class="col-lg-4 col-md-12 my-auto text-end">
                  <i
                    type="button"
                    @click="typeClick('editHome')"
                    data-bs-toggle="modal"
                    data-bs-target="#Modal"
                    class="material-icons text-white position-absolute end-4 translate-middle"
                  >
                    edit
                  </i>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body px-0 pb-2">
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      La Bannière d'accueil
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="align-middle" v-if="!this.home_banner">
                      <div class="d-flex px-3 text-center">
                        <div class="my-auto">
                          <span
                              :style="{ 'margin-right': '100%' }"
                              class="badge bg-gradient-secondary"
                          >Aucune Image</span>
                        </div>
                      </div>

                    </td>
                    <td class="align-middle" v-if="this.home_banner">


                  <div class="d-flex px-3 text-center">
                    <div class="my-auto">
                      <div
                        type="button"
                        @click="typeClick('banner2')"
                        data-bs-toggle="modal"
                        data-bs-target="#Modal"
                      >
                        <img
                          v-if="!this.home_banner.includes('.mp4')"
                          :src="this.home_banner"
                          class="avatar avatar-sm mx-5 border-radius-lg"
                          alt="bannière"
                        />
                        <video
                          v-if="this.home_banner.includes('.mp4')"
                          :src="this.home_banner"
                          class="avatar avatar-sm me-3 mx-5 border-radius-lg"
                        />
                      </div>
                    </div>
                  </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="table align-items-center mt-5 mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Produits d'accueil
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Barcode
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Image D'accueil
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(product, index) in this.home_object" :key="index">
                    <td>
                      <div class="d-flex px-2">
                        <div class="my-auto mx-5">
                          <h6 class="mb-0 text-sm">
                            {{ product.name }}
                          </h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex px-2">
                        <div class="my-auto">
                          <h6 class="mb-0 text-sm">{{ product.barcode }}</h6>
                        </div>
                      </div>
                    </td>

                    <td v-if="!product.homepage_image">
                      <span
                        :style="{ 'margin-right': '100%' }"
                        class="badge mx-3 bg-gradient-secondary"
                        >Aucune Image</span
                      >
                    </td>
                    <td v-if="product.homepage_image">
                      <div
                        type="button"
                        @click="
                          fillBanner(product.homepage_image, product.name),
                            typeClick('banner')
                        "
                        data-bs-toggle="modal"
                        data-bs-target="#Modal"
                      >
                        <img
                          :src="product.homepage_image"
                          class="avatar avatar-sm mx-5 border-radius-lg"
                          alt="bannière"
                        />
                      </div>
                    </td>

                    <td>
                      <button
                        class="btn btn-link text-secondary mb-0"
                        @click="
                          fillProduct(
                            product.name,
                            product.barcode,
                            product.homepage_image
                          ),
                            typeClick('editProduct')
                        "
                        data-bs-toggle="modal"
                        data-bs-target="#Modal"
                      >
                        <a
                          class="btn btn-link text-dark px-3 mb-0"
                          data-toggle="tooltip"
                          data-original-title="Edit user"
                        >
                          <i
                            class="fas fa-pencil-alt text-dark me-2"
                            aria-hidden="true"
                          ></i>

                          Editer
                        </a>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                class="modal fade"
                id="Modal"
                tabindex="-1"
                aria-labelledby="ModalBannerLabel"
                aria-hidden="true"
                ref="modalRef"
              >
                <div class="modal-dialog modal-lg">
                  <banner-modal
                    v-if="this.modalType == 'banner'"
                    :ProductName="this.prod_Name"
                    :ImageUrl="this.prod_Image"
                  ></banner-modal>
                  <banner-modal-2
                    v-if="this.modalType == 'banner2'"
                    :ImageUrl="this.home_banner"
                    :modal-ref="this.$refs.modalRef"
                  ></banner-modal-2>
                  <home-edit-modal
                    v-if="this.modalType == 'editHome'"
                    :HomeId="this.home_id"
                    @homeedited="refreshHome"
                  ></home-edit-modal>
                  <product-edit-modal-2
                    v-if="this.modalType == 'editProduct'"
                    :ProductName="this.prod_Name"
                    :ProductBarcode="this.prodBarCode"
                    :ProductImage="this.prod_Image"
                    @homeimageedited="refreshHome"
                  ></product-edit-modal-2>
                  <!-- <category-edit-modal v-if="this.modalType == 'editCategory'" :CategorySlug="this.categorySlug" :CategoryName="this.categoryName"></category-edit-modal>                         -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import axios from "axios";
import { mapState } from "vuex";
import BannerModal2 from "../components/BannerModal2.vue";
import HomeEditModal from "../components/HomeEditModal.vue";
import BannerModal from "../components/BannerModal.vue";
import ProductEditModal2 from "../components/ProductEditModal2.vue";

export default {
  computed: mapState(["allProduct"]),
  watch: {
    allProduct(newValue, oldValue) {
      // console.log("SAH : ", oldValue);
      if (newValue) {
        this.products = newValue;
      }
    },
  },
  mounted() {
    this.$store.dispatch("setAllProduct");
    this.token = JSON.parse(localStorage.user).token;
    this.products = this.$store.state.allProduct;
    this.getHomePage();
  },
  name: "Homepage",
  components: {
    BannerModal2,
    HomeEditModal,
    BannerModal,
    ProductEditModal2,
  },
  data: function () {
    return {
      products: reactive([]),
      home_object: [],
      home_banner: "",
      prod_Image: "",
      prodBarCode: "",
      token: "",
      prod_Name: "",
      home_id: 0,
      modalType: "",
      Axiosheader: {
        "Content-Type": "application/json",
        Authorization: "token " + this.token,
      },
    };
  },
  methods: {
    typeClick(type) {
      // console.log("product list = ", this.products);
      this.modalType = type;
    },
    refreshHome() {
      // this.getAllProduct();
      window.location.reload();
    },
    fillBanner(image, name) {
      // console.log("banner url : ", image);
      this.prod_Image = image;
      this.prod_Name = name;
    },
    fillProduct(name, barcode, homeImage) {
      this.prod_Name = name;
      this.prodBarCode = barcode;
      this.prod_Image = homeImage;
    },
    getHomePage() {
      axios
        .get(`${this.$store.state.backendUrl}home/`, this.Axiosheader)
        .then((response) => {
          // console.log("HOME IS = ", response.data);
          this.home_id = response.data[0].id;
          this.home_object = response.data[0].home_product;
          this.home_banner = response.data[0].home_banner;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
