import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Tables from "../views/Tables.vue";
import Statistics from "../views/Statistics.vue";
import Homepage from "../views/Homepage.vue";
import SignIn from "../views/SignIn.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: isLogged,
  },
  {
    path: "/dashboard",
    name: "Répartition globale des statistiques",
    component: Dashboard,
    beforeEnter: authGuard,
  },
  {
    path: "/tables",
    name: "Gestion de catalogues",
    component: Tables,
    beforeEnter: authGuard,
  },
  {
    path: "/stats",
    name: "Statistiques",
    component: Statistics,
    beforeEnter: authGuard,
  },
  {
    path: "/homepage",
    name: "Page d'accueil",
    component: Homepage,
    beforeEnter: authGuard,
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: SignIn,
    beforeEnter: authGuard2,
  },
];

function isLogged() {
  if (localStorage.getItem("user")) {
    return "/dashboard";
  } else {
    return "/sign-in";
  }
}

function authGuard(to, from, next) {
  if (localStorage.getItem("user")) {
    next();
  } else {
    next("/sign-in");
  }
}

function authGuard2(to, from, next) {
  if (localStorage.getItem("user")) {
    next("/dashboard");
  } else {
    next();
  }
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
