<template>
    <h6 class="mb-0"> <i class="fas fa-barcode"></i> {{ title }}</h6>
      <div class="py-3 pe-1">
        <div class="custom-container mt-1 px-0 pt-1 pb-1">
          <vmd-button
            v-for="statType in statTypeList"
            :key="`btn-${statType}`"
            :id="`btn-${statType}`"
            @click="selectStatType"
            :color="selectedStatType == statType ? 'success' : 'light'"
            class="btn w-100 m-1 d-flex align-items-center justify-content-center"
          >
            {{ btnTypeTexts[statType] }}
          </vmd-button>
        </div>
      </div>
</template>

<script>
import { mapState } from "vuex";
import VmdButton from "@/components/VmdButton.vue";

export default {
  setup() {
    let btnTypeTexts = {
      scan: "Scan",
      press: "Appui",
    };
    let btnOffColor = "#000";
    return {
      btnTypeTexts,
      btnOffColor,
    };
  },
  methods: {
    selectStatType($event) {
      let statType = $event.target.id.split("-")[1];

      this.$store.commit("stats/selectStatType", statType);
    },
  },
  computed: mapState("stats", ["statTypeList", "selectedStatType"]),

  props: {
    title: String,
  },
  components: {
    VmdButton,
  },
};
</script>
