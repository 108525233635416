<template>
  <div class="bg-white">
    <div class="container top-0 position-sticky z-index-sticky">
      <div class="row">
        <div class="col-12">
          <navbar
              isBlur="blur my-3 py-2 mt-4 start-0 end-0 mx-4 shadow blur border-radius-lg"
              btnBackground="bg-gradient-success"
              v-bind:darkMode="true"
          />
        </div>
      </div>
    </div>
    <main class="mt-0 main-content">
      <section>
        <div class="page-header min-vh-100">
          <div class="container">
            <div class="row">
              <div
                  class="col-6 d-lg-flex d-none h-100 my-auto pe-0 ps-0 position-absolute top-0 start-0 text-center justify-content-center flex-column"
              >
                <div
                    class="position-relative h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center"
                    :style="{
                    backgroundImage:
                      'url(' +
                      require('@/assets/img/illustrations/loreal-rouge.jpg') +
                      ')',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat'
                  }"
                ></div>
              </div>
              <div
                  class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column ms-auto me-auto ms-lg-auto me-lg-5"
              >
                <div class="card card-plain">
                  <div class="pb-0 card-header bg-transparent mb-4">
                    <img
                        src="@/assets/img/illustrations/logo-loreal-paris.jpg"
                        class="img-fluid border-radius-lg mb-3"
                        alt="fiche"
                    />
                    <h4 class="font-weight-bolder">Connectez-vous !</h4>
                    <p class="mb-0">
                      Bienvenue sur votre plateforme de gestion de contenus
                    </p>
                  </div>
                  <div class="card-body">
                    <form role="form" @submit.prevent="onSubmit">
                      <div class="mb-3">
                        <div class="input-group input-group-outline null">
                          <label class="form-label">Nom d'utilisateur</label>
                          <input
                            id="username" type="text" v-model="model.username" class="form-control form-control-default" name="username"
                            placeholder="" isrequired="false">
                        </div>
                      </div>
                      <div class="mb-3">
                        <div class="input-group input-group-outline null">
                          <label class="form-label">Mot de passe</label>
                          <input
                              id="password" type="password" v-model="model.password" class="form-control form-control-default" name="password"
                              placeholder="" isrequired="false">
                        </div>
                      </div>
                      <div class="text-center">
                        <material-button
                            class="mt-4"
                            type="submit"
                            variant="gradient"
                            color="success"
                            fullWidth
                            size="lg"
                        >Je me connecte</material-button
                        >
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";
import {reactive} from "vue";
import store from "@/store";
import setMaterialInput from "@/assets/js/material-input";

export default {
  name: "sign-in",
  mounted() {
    setMaterialInput();
  },
  setup() {
    const model = reactive({ username: "", password: "" });

    function onSubmit() {
      store.dispatch("login", model);
    }

    return {
      model,
      onSubmit,
    };
  },
  data: function () {
    return {
      username: "",
      password: "",
    };
  },
  components: {
    MaterialInput,
    MaterialButton,
  },
  beforeMount() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
  },

};
</script>
