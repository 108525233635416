<template>
<!--        <h6 class="mb-0">-->
<!--          {{ title }}-->
<!--        </h6>-->

        <div class="my-auto ms-3">
          <h6 v-if="dateRange.start" class="mb-0">
            <i class="fas fa-calendar-alt me-2" aria-hidden="true"></i>
            Du {{ dateRangeDisplay.start }}  au {{ dateRangeDisplay.end }}
          </h6>
        </div>


      <div class="border-radius-md py-3 pe-1">
        <DatePicker
          v-model="dateRange"
          :value="dateRange"
          mode="date"
          is-range
          is-expanded
          locale="fr"
          color="green"
        />
      </div>

</template>

<script>
import { reactive } from "vue";
import "v-calendar/dist/style.css";
import { DatePicker } from "v-calendar";
export default {
  setup() {
    return {
      selectAttrs: {
        highlight: {
          color: "#66bb6a",
          backgroundColor: "#66bb6a",
        },
      },
      range: reactive({
        start: null,
        end: null,
      }),
    };
  },

  components: {
    DatePicker,
  },
  props: {
    title: {
      required: true,
    },
  },
  computed: {
    dateRange: {
      set(val) {
        this.convertDateToQueryFormat(val);
      },
      get() {
        return this.range;
      },
    },
    dateRangeDisplay() {
      return {
        start: this.displayDateFormat(this.range.start),
        end: this.displayDateFormat(this.range.end),
      };
    },
  },
  created() {
    this.$store.dispatch("getCurrentMonthRange");
    this.range.start = this.$store.state.startDate;
    this.range.end = this.$store.state.endDate;
  },
  mounted() {
    this.convertDateToQueryFormat(this.range);
  },
  methods: {
    convertDateToQueryFormat(val) {
      let start = new Date(val.start);
      let end = new Date(val.end);

      let formattedStart = this.queryDateFormat(start);
      let formattedEnd = this.queryDateFormat(end);

      this.range.start = formattedStart;
      this.range.end = formattedEnd;

      this.$store.commit("stats/selectDateRange", this.range);
    },
    queryDateFormat(dateObject) {
      const day = dateObject.getDate();
      const monthIndex = dateObject.getMonth();
      const year = dateObject.getFullYear();
      const myFormattedDate =
        year +
        "-" +
        (monthIndex + 1 >= 10 ? monthIndex + 1 : "0" + (monthIndex + 1)) +
        "-" +
        (day >= 10 ? day : "0" + day);
      return myFormattedDate;
    },
    displayDateFormat(dateStr, options = {}) {
      let dateObject = new Date(dateStr);
      return dateObject.toLocaleDateString("fr", options);
    },
  },
};
</script>
