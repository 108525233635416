<template>
  <h6 class="mb-0"><i class="fas fa-tablet"></i> {{ title }}</h6>
  <div class="py-3 pe-1">
    <div class="custom-container mt-1 px-0 pt-1 pb-1">
      <!-- <p class="mx-auto my-1">Ecran {{ selectedDevice.id }}</p> -->

      <multiselect
        v-model="selectedDeviceProxy"
        placeholder="Tous les écrans"
        :disabled="selectedStore === null || selectedStore === undefined"
        :options="usedDevices"
        :multiple="false"
        :close-on-select="true"
        @select="selectDevice"
        @remove="deselectDevice"
        label="id"
        track-by="id"
        :taggable="false"
        select-label="Cliquez pour sélectionner"
        deselect-label="Cliquez pour désélectionner"
      ></multiselect>
      <!-- <ul class="clickable-list">
            <li
              :id="'device-' + device.id"
              :key="device.id"
              :class="[
                selectedDevice != null && selectedDevice.id == device.id
                  ? 'selected'
                  : '',
              ]"
              @click="selectDevice"
              v-for="device in usedDevices"
            >
              Ecran {{ device.id }}
            </li>
          </ul> -->
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  data() {
    return {
      value: null,
    };
  },
  methods: {
    selectDevice($event) {
      // let deviceId = $event.target.id.split("-")[1];
      // let device;
      // if (this.selectedDevice == null) {
      //   device = this.$store.state.stats.statDeviceList.find(
      //     (element) => element.id == deviceId
      //   );
      // } else if (
      //   this.selectedDevice != null &&
      //   deviceId != this.selectedDevice.id
      // ) {
      //   device = this.$store.state.stats.statDeviceList.find(
      //     (element) => element.id == deviceId
      //   );
      // } else {
      //   device = null;
      // }
      this.$store.commit("stats/selectDevice", $event);
    },
    deselectDevice() {
      this.$store.commit("stats/selectDevice", null);
    },
  },
  computed: {
    ...mapState("stats", ["statDeviceList", "selectedDevice", "selectedStore"]),
    ...mapGetters("stats", ["usedDevices"]),
    selectedDeviceProxy: {
      get() {
        return this.selectedDevice;
      },
      set(newValue) {
        this.$store.commit("stats/selectDevice", newValue);
      },
    },
  },

  props: {
    title: String,
  },
};
</script>
