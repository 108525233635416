<template>
  <div class="card z-index-2">
    <div
      class="card-header p-0 position-relative mt-1 mx-3 z-index-2 bg-transparent"
    >
      <div class="card-body">
        <h6 class="mb-0">{{ title }}</h6>
        <p class="text-sm">{{ desc }}</p>
      </div>
      <div class="border-radius-lg py-3 pe-1">
        <div class="chart">
          <canvas :id="id" class="chart-canvas" height="300"></canvas>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import { mapState, mapGetters } from "vuex";
export default {
  name: "chart-bars",
  setup() {
    let chart = {};
    return {
      chart,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    desc: {
      type: String,
    },

    type: {
      type: String,
      default: "bar",
    },
    labels: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      required: false,
    },
    containerStyle: {
      required: false,
    },
    datasets: {
      required: true,
    },
    statType: { required: true },
  },
  computed: {
    ...mapState("stats", ["statData"]),
    ...mapGetters(["groupByKey"]),
  },
  mounted() {
    this.dataReady();
    // console.log("OPTIONS", this.options);
    // console.log("datasets in chart", this.datasets);
    // console.log("data ready ", this.dataReady());
  },
  methods: {
    // TODO docker

    dataReady() {
      var ctx = document.getElementById(this.id).getContext("2d");
      this.chart = new Chart(ctx, {
        type: this.type,
        data: {
          labels: this.labels,
          datasets: this.datasets,
        },
        options: this.options,
      });
      // console.log(this.id, "DATSETS AFTER INIT ", this.chart.data.datasets);
    },
  },
  watch: {
    datasets(newValue) {
      // console.log(this.id, "--DATASETS CHANGED old, new--", oldValue, newValue);
      // console.log(this.id, this.chart.data.datasets);
      this.chart.data.labels = this.labels;
      this.chart.data.datasets = newValue;
      this.chart.update();
    },
  },
};
</script>
