<template>
  <div class="modal-content">
    <form
      label="Catégorie:"
      role="form"
      class="text-start mt-3"
      @submit="editCategory"
    >
      <div class="modal-header">
        <h5 class="modal-title" id="ModalCategoryLabel">
          {{ "Modifier la catégorie de produit: " + CategoryName }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <h6 for="formFile2">{{ "Nom de la catégorie" }}</h6>
          <vmd-input
            type="string"
            id="NameCategory"
            name="categorieName"
            :value="CategoryName"
            @input="this.updateInputValue"
          ></vmd-input>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" @click="createCategory" class="btn btn-success">
          Modifier
        </button>
        <button
          type="button"
          @click="console.log(this.tmp)"
          class="btn btn-secondary"
          data-bs-dismiss="modal"
        >
          Fermer
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import VmdInput from "./VmdInput.vue";
import axios from "axios";
export default {
  components: { VmdInput },
  props: {
    CategorySlug: String,
    CategoryName: String,
  },
  watch: {
    CategoryName: function (newValue) {
      this.categname = newValue;
      console.log("trigger", newValue, this.categname);
    },
    CategorySlug: function (newValue) {
      console.log("trigger", newValue);
    },
  },
  mounted() {
    this.token = JSON.parse(localStorage.user).token;
  },
  name: "categoryEditModal",
  data: function () {
    return {
      token: "",
      categname: "",
    };
  },
  methods: {
    updateInputValue(event) {
      if (event.target.id == "NameCategory") {
        this.categname = event.target.value;
      }
      // console.log(this.prodname);
    },
    editCategory(event) {
      event.preventDefault();
      //   console.log(event.target.elements.mainCategorie.value)
      const data = new FormData();
      var Axiosheader = {
        headers: { Authorization: "token " + this.token },
      };
      data.append("name", this.categname);
      // console.log(data)
      axios
        .patch(
          `${this.$store.state.backendUrl}category/` + this.CategorySlug,
          data,
          Axiosheader
        )
        .then((response) => {
          this.products = response.data;
          this.$emit("categoryupdated");
          // console.log(response);
        })
        .catch(() => {
          alert("Attention, ce nom de catégorie a déja été renseigné.");
        });
    },
  },
};
</script>
