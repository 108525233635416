/* eslint-disable*/
import axios from "axios";

export default {
    state: {
        dashboardMiniCardData: null,
        dashboardMiniCardDataReady: false,
        dashboardData: null,
        dashboardDataReady: false,
    },
    mutations: {
        dashboardMiniCardData(state, data) {
            state.dashboardMiniCardData = data;
        },
        dashboardMiniCardDataReady(state, loading) {
            state.dashboardMiniCardDataReady = loading;
        },
        statData(state, { data, ready }) {
            state.statData = data;
            state.statDataReady = ready;
        },
    },
    actions: {
        initDashboardMiniCardData({ commit, state, rootState }) {
            let headers = {
                Authorization: "token " + JSON.parse(localStorage.user).token,
            };
            axios.get(`${rootState.backendUrl}dashboard/`, { headers }).then((res) => {
                commit("dashboardMiniCardData", res.data);
                commit("dashboardMiniCardDataReady", true);
            });
        },
    },
    getters: {},
};
