<template>
  <div class="modal-content">
    <form
      label="Catégorie:"
      role="form"
      class="text-start mt-3"
      @submit="createCategory"
    >
      <div class="modal-header">
        <h5 class="modal-title" id="ModalCategoryLabel">
          Créer votre nouvelle catégorie de produit
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <select v-model="this.catId" name="mainCategorie" class="form-select">
          <option selected value="0">
            {{ "ㅤSéléctionner une catégorie parent" }}
          </option>
          <option
            v-for="(category, index) in this.completelist"
            v-bind:value="category.id"
            :key="index"
          >
            >{{ category.name }}
          </option>
        </select>
        <br />
        <div class="mb-3">
          <vmd-input
            id="NameCategory"
            type="string"
            label="Nom de catégorie"
            name="categorieName"
            @input="this.updateInputValue"
            :isRequired="true"
          ></vmd-input>
        </div>
        <p v-if="this.validCategoryName == false" class="error">
          <span style="color: red"
            >Vous devez obligatoirement renseigner un nom de catégorie.</span
          >
        </p>
      </div>
      <div class="modal-footer">
        <button type="submit" @click="createCategory" class="btn btn-success">
          Créer
        </button>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Fermer
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import VmdInput from "./VmdInput.vue";
import axios from "axios";
export default {
  components: { VmdInput },
  props: {
    CategoryList: Array,
  },
  mounted() {
    this.token = JSON.parse(localStorage.user).token;
    this.setAllCategory(this.CategoryList);
    // console.log("FULL LIST = ",this.completelist)
  },
  name: "categoryCreateModal",
  data: function () {
    return {
      token: "",
      catId: 0,
      categname: "",
      completelist: [],
      tmp: {},
      validCategoryName: true,
      validFields: false,
    };
  },
  methods: {
    updateInputValue(event) {
      if (event.target.id == "NameCategory") {
        this.categname = event.target.value;
      }
      // console.log(this.prodname);
    },
    setAllCategory(categorylist) {
      // console.log("FULL LIST", categorylist)
      categorylist.map((category) => {
        this.completelist = this.completelist.concat(category);
        // console.log(this.completelist)
        if (category.children.length > 0) {
          this.setAllCategory(category.children);
        }
      });
    },
    checkFormField() {
      if (this.categname == "") {
        this.validCategoryName = false;
      } else {
        this.validCategoryName = true;
      }
      if (this.validCategoryName == true) {
        this.validFields = true;
      }
    },
    createCategory(event) {
      event.preventDefault();
      //   console.log(event.target.elements.mainCategorie.value)
      this.checkFormField();
      if (this.validFields == true) {
        const data = new FormData();
        var Axiosheader = {
          headers: {
            Authorization: "token " + this.token,
          },
        };
        data.append("name", this.categname);
        if (this.catId != 0) {
          data.append("parent", this.catId);
        } else {
          data.append("parent", "");
          // console.log("no parent");
        }
        // console.log(data)
        axios
          .post(`${this.$store.state.backendUrl}category/`, data, Axiosheader)
          .then((response) => {
            console.log("OUGA BOUGA :", response);
            this.$emit("categorycreated");
          })
          .catch(() => {
            alert("Attention, ce nom de catégorie a déja été renseigné.");
          });
      }
    },
  },
};
</script>
