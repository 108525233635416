<template>
    <div class="modal-content">
        <div class="modal-header">
            <h6 class="modal-title" id="ModalBannerLabel">{{"Bannière de la page d'accueil"}}</h6>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <img v-if="!ImageUrl.includes('.mp4')" v-bind:src='this.ImageUrl' style="max-height:474px; max-width:757px"/>
            <video ref="videoRef" v-if="ImageUrl.includes('.mp4')" loop autoplay controls v-bind:src='this.ImageUrl' style="max-height:474px; max-width:757px"/>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
          </div>
    </div>
</template>

<script>
export default {
  name: "bannerModal",
  props: {
    ProductName: String,
    ImageUrl: String,
    modalRef: Object,
  },
  mounted() {
    this.modalRef.addEventListener("hide.bs.modal", () => this.stopVideo());
    this.modalRef.addEventListener("show.bs.modal", () => {
      this.$refs.videoRef?.load();
      this.$refs.videoRef?.play();
    });
  },
  methods: {
    stopVideo() {
      // if isvideo / not image
      this.$refs.videoRef?.pause();
    },
  },
};
</script>
