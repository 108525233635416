<template>
  <!--  <aside-->
  <!--         class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 ms-3 fixed-start ms-3 bg-gradient-dark fixed-start">-->
  <!--    <div class="sidenav-header">-->
  <!--            <ul class="navbar-nav">-->
  <!--          <li class="nav-item"><a data-bs-toggle="collapse" href="#pagesExamples" aria-controls="pagesExamples"-->
  <!--                                  aria-expanded="false" class="nav-link active collapsed">-->
  <!--            <div class="text-center d-flex align-items-center justify-content-center me-2"><i-->
  <!--                class="material-icons-round opacity-10">image</i></div>-->
  <!--            <span class="nav-link-text ms-1">Pages</span></a>-->
  <!--            <div id="pagesExamples" class="collapse" style="">-->
  <!--              <ul class="nav pe-0">-->
  <!--                <li class="nav-item"><a class="nav-link text-white" data-bs-toggle="collapse" aria-expanded="true"-->
  <!--                                        href="#profileExample"><span class="sidenav-mini-icon">P</span><span-->
  <!--                    class="sidenav-normal me-3 ms-2 ps-1">Profile <b class="caret"></b></span></a>-->
  <!--                  <div id="profileExample" class="collapse show" style="">-->
  <!--                    <ul class="nav nav-sm flex-column">-->
  <!--                      <li class="nav-item active"><a href="#/pages/profile/overview" class="nav-link"><span-->
  <!--                          class="sidenav-mini-icon text-white">P</span><span-->
  <!--                          class="sidenav-normal me-3 ms-3 ps-1 text-white text-white">Profile Overview</span></a></li>-->
  <!--                      <li class="nav-item active"><a href="#/pages/profile/projects" class="nav-link"><span-->
  <!--                          class="sidenav-mini-icon text-white">A</span><span-->
  <!--                          class="sidenav-normal me-3 ms-3 ps-1 text-white text-white">All Projects</span></a></li>-->
  <!--                    </ul>-->
  <!--                  </div>-->
  <!--                </li>-->

  <!--              </ul>-->
  <!--            </div>-->
  <!--          </li>-->
  <!--        </ul>-->
  <!--    </div>-->
  <!--  </aside>-->

  <div>
    <div v-for="(category, index) in this.AllCategory" :key="index">
      <div style="margin-left: 10px">
        <div
          class="d-flex justify-content-center"
          style="flex-direction: column"
        >
          <div
            style="flex-direction: row; display: flex; align-items: center"
            class="category"
          >
            <span class="type" @click="nodeClicked(category)">
              <i
                :class="
                  isExpanded(category)
                    ? 'fas fa-minus me-2'
                    : 'fas fa-plus me-2'
                "
                aria-hidden="true"
              ></i>
            </span>
            <h6 class="mb-0 text-sm" @click="filterProduct(category)">
              <a href="javascript:;">{{ category.name }}</a>
            </h6>
            <div class="ms-auto text-end">
              <a
                class="btn btn-link text-dark px-3 mb-0"
                @click="
                  typeClick2('editCategory2'),
                    fillCategoryName(category.slug, category.name, index)
                "
                data-bs-toggle="modal"
                :data-bs-target="
                  '#Modaleuh' +
                  category.name.replaceAll(' ', '') +
                  this.ModalNum
                "
                data-toggle="tooltip"
                data-original-title="Edit user"
              >
                <i
                  class="fas fa-pencil-alt text-dark me-2"
                  aria-hidden="true"
                ></i>
                Editer
              </a>
              <a
                class="btn btn-link text-danger text-gradient px-3 mb-0"
                @click="deleteCategory(category.slug)"
                data-toggle="tooltip"
                data-original-title="Edit user"
              >
                <i class="far fa-trash-alt me-2" aria-hidden="true"></i>
                Supp
              </a>
            </div>
          </div>
          <catlist
            v-if="isExpanded(category) && category.children"
            @subfilter="filterChildProduct"
            @filter="justEmit"
            @categorycreated="refreshCategory"
            @categoryupdated="refreshCategory"
            :AllCategory="category.children"
            :child="true"
          />
        </div>
      </div>
      <div
        class="modal fade"
        :id="'Modaleuh' + category.name.replaceAll(' ', '') + this.ModalNum"
        tabindex="0"
        aria-labelledby="ModalBannerLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <category-edit-modal
            @categoryupdated="refreshCategory"
            v-if="this.modalType2 == 'editCategory2'"
            :CategorySlug="this.categorySlug"
            :CategoryName="this.categoryName"
            :depth="this.currDepth"
          ></category-edit-modal>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CategoryEditModal from "./CategoryEditModal.vue";
import axios from "axios";
export default {
  components: {
    CategoryEditModal,
  },
  props: {
    AllCategory: Array,
    depth: Number,
    child: Boolean,
  },
  mounted() {
    this.currDepth = this.depth;
    this.currDepth++;
    this.token = JSON.parse(localStorage.user).token;
    this.getAllCategory();
  },
  name: "catlist",
  data: function () {
    return {
      categoryList: [],
      token: "",
      modalType2: "",
      categoryName: "",
      expanded: [],
      categorySlug: "",
      ModalNum: "",
      currDepth: 0,
    };
  },
  methods: {
    targetModal(categoryName) {
      console.log("AAAAAAAAAAAAAAH ", categoryName);
    },
    refreshCategory() {
      this.$emit("categorycreated");
    },
    isExpanded(node) {
      return this.expanded.indexOf(node) !== -1;
    },
    nodeClicked(node) {
      if (!this.isExpanded(node)) {
        this.expanded.push(node);
      } else {
        this.expanded.splice(this.expanded.indexOf(node));
      }
    },
    getAllCategory() {
      var Axiosheader = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + this.token,
        },
      };

      axios
        .get(`${this.$store.state.backendUrl}category/`, Axiosheader)
        .then((response) => {
          this.categoryList = response.data;
          console.log(response.data);
        });
    },
    filterProduct(selectedcategory) {
      // console.log("EMIT EVENT"),
      //   console.log("CATEGORY VALUE IS : ", selectedcategory);
      if (selectedcategory.parent != null) {
        // console.log("INSIDE SUBFILTER");
        this.$emit("subfilter", selectedcategory);
      }
      this.$emit("filter", selectedcategory);
    },
    filterChildProduct(selectedcategory) {
      this.$emit("filter", selectedcategory);
    },
    justEmit(selectedcategory) {
      this.$emit("filter", selectedcategory);
    },
    typeClick2(type) {
      this.modalType2 = type;
    },
    logCat() {
      console.log(this.AllCategory);
    },
    fillCategoryName(slug, name, index) {
      console.log("NAME IS = ", name);
      this.categorySlug = slug;
      this.categoryName = name;
      this.ModalNum = index;
      console.log("ZOG ZOG = ", "Modaleuh" + this.categoryName + this.ModalNum);
    },
    deleteCategory(categoryslug) {
      var Axiosheader = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + this.token,
        },
      };

      if (
        window.confirm(
          "Souhaitez vous vraiment supprimer cette catégorie ?\n\nTous les produits et toutes les sous-catégories associées à celle-ci seront également supprimés."
        )
      ) {
        // console.log("Slug is : ", categoryslug);
        axios
          .delete(
            `${this.$store.state.backendUrl}category/` + categoryslug,
            Axiosheader
          )
          .then((response) => {
            // console.log(response);
            window.location.reload();
          });
      }
    },
  },
};
</script>

<style scoped>
.node {
  text-align: left;
  font-size: 18px;
}
.type {
  margin-right: 10px;
}
</style>
