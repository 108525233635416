<template>
  <h6 class="mb-0">
    <i class="fas fa-store" aria-hidden="true"></i> {{ title }}
  </h6>
  <div class="py-3 pe-1">
    <div class="custom-container mt-1 px-0 pt-1 pb-1">
      <multiselect
        v-model="selectedStoreProxy"
        :options="statStoreList"
        :multiple="false"
        :close-on-select="true"
        @select="selectStore"
        label="name"
        track-by="name"
        :taggable="false"
        select-label="Cliquez pour sélectionner"
        deselect-label="Cliquez pour désélectionner"
        :allow-empty="false"
        :clear-on-select="false"
      ></multiselect>
      <!--          <div class="input-group input-group-outline null"><label class="form-label">Search here</label><input-->
      <!--              id="search" type="text" class="form-control form-control-default" name="" placeholder=""-->
      <!--              isrequired="false"></div>-->
      <!-- <vmd-input-2
            v-model="storeSearch"
            id="store-list-input"
            type="text"
            label="Magasin"
            name="Magasin"
          /> -->
      <!-- <ul v-if="!showFiltered" class="clickable-list">
            <li
              :id="'store-' + store.id"
              :key="store.id"
              :class="[
                selectedStore != null && selectedStore.id == store.id
                  ? 'selected'
                  : '',
              ]"
              @click="selectStore"
              v-for="store in statStoreList"
            >
              {{ store.name }}
            </li>
          </ul>
          <ul v-else class="clickable-list">
            <li
              :id="'store-' + store.id"
              :key="store.id"
              :class="[selectedStore.id == store.id ? 'selected' : '']"
              @click="selectStore"
              v-for="store in filteredStoreList"
            >
              {{ store.name }}
            </li>
          </ul> -->
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { mapState } from "vuex";
import VmdInput2 from "../../../components/VmdInput2.vue";

export default {
  setup() {
    let storeSearch = ref("");
    let filteredStoreList = ref("");
    let storeSearchResult = [];

    return {
      storeSearch,
      filteredStoreList,
      storeSearchResult,
    };
  },
  data() {
    return {
      value: null,
    };
  },
  methods: {
    selectStore($event) {
      // let storeId = $event.target.id.split("-")[1];
      // let store = this.$store.state.stats.statStoreList.find(
      //   (element) => element.id == storeId
      // );
      // this.$store.commit("stats/selectStore", store);
      this.$store.commit("stats/selectStore", $event);
    },
  },
  computed: {
    ...mapState("stats", ["statStoreList", "selectedStore"]),
    showFiltered() {
      return this.storeSearch != "";
    },
    selectedStoreProxy: {
      get() {
        return this.selectedStore;
      },
      set(newValue) {
        this.$store.commit("stats/selectStore", newValue);
      },
    },
  },
  watch: {
    storeSearch(value) {
      this.filteredStoreList = this.statStoreList.filter((element) => {
        return element.name.toLowerCase().includes(value);
      });
    },
  },

  props: {
    title: String,
  },
  components: {
    VmdInput2,
  },
};
</script>
