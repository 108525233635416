import { createStore } from "vuex";
import StatsStore from "./modules/stats";
import axios from "axios";
import router from "../router";
import DashboardStore from "./modules/dashboard";

export default createStore({
  modules: {
    stats: {
      namespaced: true,
      ...StatsStore,
    },
    dashboard: {
      namespaced: true,
      ...DashboardStore,
    },
  },
  state: {
    appName: "Oreal",
    backendUrl: "/api/",
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    sidebarType: "bg-gradient-dark",
    isRTL: false,
    mcolor: "success",
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    isDarkMode: false,
    allProduct: [],
    fullProduct: [],
    token: "",
    startDate: null,
    endDate: null,
    selectedStore: null,
  },
  mutations: {
    setAllProduct(state, data) {
      state.allProduct = data;
      state.fullProduct = data;
    },
    currentMonthRange(state) {
      var date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth();
      state.startDate = new Date(y, m, 1);
      state.endDate = new Date(y, m + 1, 0);
    },
    setToken: (state, model) => {
      state.token = model.token;
    },
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");

      if (sidenav_show.classList.contains("g-sidenav-pinned")) {
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
  },
  actions: {
    getCurrentMonthRange({ commit }) {
      commit("currentMonthRange");
    },
    setAllProduct({ commit, state }) {
      var Axiosheader = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + JSON.parse(localStorage.user).token,
        },
      };

      axios.get(`${state.backendUrl}product`, Axiosheader).then((res) => {
        commit("setAllProduct", res.data);
      });
    },
    login: async ({ state }, model) => {
      let resp = await axios.post(`${state.backendUrl}auth/login/`, model);
      if (resp.data.token) {
        localStorage.setItem("user", JSON.stringify(resp.data));
        router.go("/dashboard");
      }
    },
  },
  getters: {
    isAuthenticated: (state) => {
      return state.token.length > 0;
    },
    groupByKey: () => (list, key, { omitKey = false, childKey = null }) => {
      return list.reduce((hash, { [key]: value, ...rest }) => {
        let objValue = childKey != null ? value[childKey] : value;
        return {
          ...hash,
          [objValue]: (hash[objValue] || []).concat(
            omitKey ? { ...rest } : { [key]: value, ...rest }
          ),
        };
      }, {});
    },
    setDataAndReady: () => (data, ready) => {
      return {
        data: data,
        ready: ready,
      };
    },
    generateRGBAStyle: () => () => {
      let getRandomInt = function getRandomInt(max) {
        return Math.floor(Math.random() * max);
      };
      let rgbaObj = {
        r: getRandomInt(255),
        g: getRandomInt(255),
        b: getRandomInt(255),
      };
      return {
        backgroundColor: `rgba(${rgbaObj.r}, ${rgbaObj.g}, ${rgbaObj.b}, 0.2)`,
        borderColor: `rgb(${rgbaObj.r}, ${rgbaObj.g}, ${rgbaObj.b})`,
      };
    },
    capitalize: () => (s) => s && s[0].toUpperCase() + s.slice(1),
  },
});
