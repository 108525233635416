<template>
  <div class="container-fluid py-4">
    <div class="row">
      <!-- First Table -->
      <div class="col-4">
        <div class="card my-4">
          <div class="p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <div class="row mb-4 mb-md-0">
                <div class="col-md-8 me-auto my-auto text-left">
                  <h6 class="text-white ps-3">Catégorie(s)</h6>
                </div>

                <div class="col-lg-4 col-md-12 my-auto text-end">
                  <h5
                    type="button"
                    class="text-white text-capitalize position-absolute top-50 end-3 translate-middle"
                    @click="typeClick('createCategory'), fillCategoryList()"
                    data-bs-toggle="modal"
                    data-bs-target="#Modal"
                  >
                    +
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      style="padding-left: 3.5rem;"
                    >
                      Nom
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      :style="{ position: 'absolute', left: '66%' }"
                    >
                      Actions
                    </th>
                    <!-- <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      <i
                        type="button"
                        @click="resetFilter()"
                        class="material-icons"
                      >
                        cleaning_services
                      </i>
                    </th> -->
                  </tr>
                </thead>
                <!-- table  category -->
                <cat-list
                  @filter="getProductsByCategory"
                  @categorycreated="refreshCategory"
                  :AllCategory="this.categories"
                ></cat-list>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- Seconde Table -->
      <div class="col-8">
        <div class="card my-4">
          <div class="p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <table>
                <th>
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                    "
                  >
                    <h6 class="text-white ps-3">
                      {{ "Produit(s) " + this.filteredName }}
                      <i
                        class="fas fa-times"
                        type="button"
                        @click="resetFilter()"
                        v-if="this.filteredName.length > 0"
                        style="
                          align-self: center;
                          bottom: 20px;
                          color: white;
                          margin-left: 10px;
                        "
                      ></i>
                    </h6>
                  </div>
                </th>
                <th>
                  <h5
                    type="button"
                    @click="typeClick('createProduct'), fillCategoryList()"
                    class="text-white text-capitalize position-absolute end-3"
                    data-bs-toggle="modal"
                    data-bs-target="#Modal"
                  >
                    +
                  </h5>
                </th>
              </table>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div class="table-responsive p-0">
              <table
                class="table align-items-center justify-content-center mb-0"
              >
                <thead>
                  <tr>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center"
                    >
                      Catégorie
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center"
                    >
                      Nom
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center"
                    >
                      Barcode
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center"
                    >
                      Fiche Produit
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center"
                    >
                      Bannière Produit
                    </th>
                    <!--                    <th-->
                    <!--                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"-->
                    <!--                    >-->
                    <!--                      Image d'accueil-->
                    <!--                    </th>-->
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(product, index) in products" :key="index">
                    <td>
                      <div class="d-flex px-3 text-center">
                        <div class="my-auto">
                          <h6 class="mb-0 text-sm">
                            {{ shortenCategoryName(product.category) }}
                          </h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex px-3 text-center">
                        <div class="my-auto">
                          <h6 class="mb-0 text-sm">{{ product.name }}</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex px-3 text-center">
                        <p class="text-sm font-weight-bold mb-0 ">
                          {{ product.barcode }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <!-- Button trigger modal -->
                      <!-- <button type="button" @click="fillBanner(product.product_banner, product.name), typeClick('banner')" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#Modal">
                      Ouvrir
                    </button> -->
                      <div
                        type="button"
                        class="text-center"
                        @click="
                          fillBanner(product.product_details, product.name),
                            typeClick('banner')
                        "
                        data-bs-toggle="modal"
                        data-bs-target="#Modal"
                        v-if="!product.product_details.includes('.mp4')"
                      >
                        <img
                          :src="product.product_details"
                          class="avatar avatar-sm border-radius-lg"
                          alt="fiche"
                        />
                      </div>
                      <div
                        type="button"
                        class="text-center"
                        @click="
                          fillBanner(product.product_details, product.name),
                            typeClick('banner')
                        "
                        data-bs-toggle="modal"
                        data-bs-target="#Modal"
                        v-if="product.product_details.includes('.mp4')"
                      >
                        <video
                          :src="product.product_details"
                          class="avatar avatar-sm border-radius-lg"
                          alt="fiche"
                        ></video>
                      </div>
                    </td>
                    <td v-if="product.product_banner.includes('.mp4')">
                      <div
                        type="button"
                        class="text-center"
                        @click="
                          fillBanner(product.product_banner, product.name),
                            typeClick('banner')
                        "
                        data-bs-toggle="modal"
                        data-bs-target="#Modal"
                      >
                        <video
                          :src="product.product_banner"
                          class="avatar avatar-sm border-radius-lg"
                          alt="bannière"
                        ></video>
                      </div>
                    </td>
                    <td v-if="!product.product_banner.includes('.mp4')">
                      <div
                        type="button"
                        class="text-center"
                        @click="
                          fillBanner(product.product_banner, product.name),
                            typeClick('banner')
                        "
                        data-bs-toggle="modal"
                        data-bs-target="#Modal"
                      >
                        <img
                          :src="product.product_banner"
                          class="avatar avatar-sm border-radius-lg"
                          alt="bannière"
                        />
                      </div>
                    </td>
                    <!--                    <td-->
                    <!--                      v-if="!product.homepage_image"-->
                    <!--                      class="align-middle text-center text-sm"-->
                    <!--                    >-->
                    <!--                      <span-->
                    <!--                        :style="{ 'margin-right': '100%' }"-->
                    <!--                        class="badge badge-sm bg-gradient-secondary"-->
                    <!--                        >Aucune Image</span-->
                    <!--                      >-->
                    <!--                    </td>-->
                    <!--                    <td v-if="product.homepage_image">-->
                    <!--                      <div-->
                    <!--                        type="button"-->
                    <!--                        @click="-->
                    <!--                          fillBanner(product.homepage_image, product.name),-->
                    <!--                            typeClick('banner')-->
                    <!--                        "-->
                    <!--                        data-bs-toggle="modal"-->
                    <!--                        data-bs-target="#Modal"-->
                    <!--                      >-->
                    <!--                        <img-->
                    <!--                          :src="product.homepage_image"-->
                    <!--                          class="avatar avatar-sm me-3 border-radius-lg"-->
                    <!--                          alt="bannière"-->
                    <!--                        />-->
                    <!--                      </div>-->
                    <!--                    </td>-->
                    <td class="align-middle">
                      <a
                        class="btn btn-link text-dark px-3 mb-0"
                        @click="fillProduct(product), typeClick('editProduct')"
                        data-bs-toggle="modal"
                        data-bs-target="#Modal"
                      >
                        <i
                          class="fas fa-pencil-alt text-dark me-2"
                          aria-hidden="true"
                        ></i>
                        Editer
                      </a>

                      <a
                        class="btn btn-link text-danger text-gradient px-3 mb-0"
                        @click="deleteProduct(product.barcode)"
                        data-toggle="tooltip"
                      >
                        <i class="far fa-trash-alt me-2" aria-hidden="true"></i>
                        Supp
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- Modal -->
              <div
                class="modal fade"
                id="Modal"
                tabindex="-1"
                aria-labelledby="ModalBannerLabel"
                aria-hidden="true"
                ref="modalRef"
              >
                <div class="modal-dialog modal-lg">
                  <banner-modal
                    v-if="this.modalType === 'banner'"
                    :ProductName="this.productName"
                    :ImageUrl="this.bannerUrl"
                    :modalRef="this.$refs.modalRef"
                  ></banner-modal>
                  <product-create-modal
                    @productcreated="refreshProduct"
                    v-if="this.modalType === 'createProduct'"
                    :CategoryList="this.categories"
                    :modal-ref="this.$refs.modalRef"
                  ></product-create-modal>
                  <product-edit-modal
                    v-if="this.modalType === 'editProduct'"
                    :ProductName="this.productName"
                    :ProductBarcode="this.prodBarCode"
                    :ProductImage="this.productHomeImage"
                    :Product="this.product"
                    :CategoryList="this.categories"
                    :modal-ref="this.$refs.modalRef"
                    @productedited="refreshProduct"
                  ></product-edit-modal>
                  <category-create-modal
                    @categorycreated="refreshCategory"
                    v-if="this.modalType === 'createCategory'"
                    :CategoryList="this.categories"
                  ></category-create-modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import axios from "axios";
import BannerModal from "../components/BannerModal.vue";
import ProductCreateModal from "../components/ProductCreateModal.vue";
import CategoryCreateModal from "../components/CategoryCreateModal.vue";
// import CategoryEditModal from '../components/CategoryEditModal.vue';
import ProductEditModal from "../components/ProductEditModal.vue";
import CatList from "../components/Catlist.vue";
import { mapState } from "vuex";
export default {
  computed: mapState(["allProduct", "fullProduct"]),
  watch: {
    allProduct(newValue, oldValue) {
      // console.log("SAH : ", oldValue);
      if (newValue) {
        this.products = newValue;
      }
    },
  },
  mounted() {
    this.$store.dispatch("setAllProduct");
    this.products = this.$store.state.allProduct;
    this.prodfulllist = this.$store.state.fullProduct;
    this.getAllCategory();
    this.token = JSON.parse(localStorage.user).token;
    // console.log("VERIF : ", this.products);
    // console.log(this.categories);
  },
  name: "Gestion de Catalogues",
  components: {
    BannerModal,
    CatList,
    ProductCreateModal,
    CategoryCreateModal,
    // CategoryEditModal,
    ProductEditModal,
  },
  data: function () {
    return {
      categories: [],
      categoryList: [],
      tmpProdList: [],
      products: reactive([]),
      prodfulllist: reactive([]),
      filteredName: "",
      modalType: "",
      bannerUrl: "",
      productName: "",
      prodBarCode: "",
      productHomeImage: "",
      token: "",
      categoryName: "",
      categorySlug: "",
      Axiosheader: {
        "Content-Type": "application/json",
        Authorization: "token " + this.token,
      },
      product: null,
    };
  },
  methods: {
    shortenCategoryName: function (categoryName) {
      let tmp = categoryName.split("/");
      let str = "";
      if (tmp.length < 2) {
        tmp.map((word) => {
          str = str.concat(word);
        });
      } else {
        tmp.map((word, index) => {
          if (index == 0) {
            str = str.concat(word);
          } else if (index == tmp.length - 1) {
            str = str.concat("/../" + word);
          }
        });
      }
      return str;
    },
    typeClick(type) {
      this.modalType = type;
    },
    refreshCategory() {
      this.getAllCategory();
      window.location.reload();
    },
    refreshProduct() {
      this.getAllProduct();
      window.location.reload();
    },
    resetFilter() {
      // console.log("proddfulllist =  ", this.prodfulllist);
      this.filteredName = "";
      this.products = this.$store.state.fullProduct;
    },
    updateProductFilter() {},
    getAllProduct() {
      var Axiosheader = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + this.token,
        },
      };

      axios
        .get(`${this.$store.state.backendUrl}product/`, Axiosheader)
        .then((response) => {
          this.products = response.data;
          // console.log("PRROOOODUCT : ", response);
        });
    },
    getProductsByCategory(categoryselect) {
      var Axiosheader = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + this.token,
        },
      };
      let productchildlist = [];
      let child = [];
      // console.log("Slug is : ", categoryselect);
      this.filteredName = "- (" + categoryselect.name + ")";
      // console.log("1 = IF");
      axios
        .get(
          `${this.$store.state.backendUrl}product/?category=` +
            categoryselect.slug,
          Axiosheader
        )
        .then((response) => {
          // console.log(response);
          this.products = response.data;
        });
      if (categoryselect.children.length > 0) {
        this.getProductsByCategory2(categoryselect);
        this.products = this.tmpProdList;
        this.tmpProdList = [];
      }
    },
    getProductsByCategory2(categoryselect) {
      var Axiosheader = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + this.token,
        },
      };
      let productchildlist = [];
      categoryselect.children.map((child) => {
        // console.log("this child is = ", child);
        if (child.children.length == 0) {
          child.products.map((product) => {
            axios
              .get(
                `${this.$store.state.backendUrl}product/?category=` +
                  child.slug,
                Axiosheader
              )
              .then((response) => {
                // console.log("thats all folks = ", response.data);
                product = response.data;
              });
            productchildlist = productchildlist.concat(product);
          });
        } else {
          this.getProductsByCategory2(child);
        }
      });
      // console.log("NEW LIST = ", productchildlist);
      this.tmpProdList = this.tmpProdList.concat(productchildlist);
    },
    getAllCategory() {
      // console.log(this.Axiosheader);
      axios
        .get(`${this.$store.state.backendUrl}category/`, this.Axiosheader)
        .then((response) => {
          this.categories = response.data;
          // console.log(response.data);
        });
    },
    fillBanner(product, name) {
      // console.log("banner url : ", product);
      this.bannerUrl = product;
      this.productName = name;
    },
    fillCategoryName(slug, name) {
      this.categorySlug = slug;
      this.categoryName = name;
    },
    fillProduct(product) {
      this.productName = product.name;
      this.prodBarCode = product.barcode;
      this.productHomeImage = product.homeImage;
      this.product = product;
    },
    fillCategoryList() {
      // console.log(this.categories);
      let index = 0;
      while (index < this.categories.length) {
        if (this.categoryList.includes(this.categories[index].id) == false) {
          // console.log(this.categories[index].id);
          this.categoryList.push(this.categories[index].id);
        }
        index++;
      }
    },
    deleteProduct(prodBarCode) {
      var Axiosheader = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + this.token,
        },
      };

      if (window.confirm("Souhaitez vous vraiment supprimer ce produit ?")) {
        // console.log("Barcode is : ", prodBarCode);
        axios
          .delete(
            `${this.$store.state.backendUrl}product/` + prodBarCode,
            Axiosheader
          )
          .then((response) => {
            this.getAllProduct();
            // console.log(response);
          });
      }
    },
    deleteCategory(categoryslug) {
      var Axiosheader = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + this.token,
        },
      };

      if (
        window.confirm(
          "Souhaitez vous vraiment supprimer cette catégorie ?\n\ntout les produits et toute les sous catégorie associé à celle-çi seront également supprimés"
        )
      ) {
        // console.log("Slug is : ", categoryslug);
        axios
          .delete(
            `${this.$store.state.backendUrl}category/` + categoryslug,
            Axiosheader
          )
          .then((response) => {
            this.getAllProduct();
            this.getAllCategory();
            // console.log(response);
          });
      }
    },
  },
};
</script>
