<template>
  <div class="modal-content">
    <form
      label="Home:"
      role="form"
      class="text-start mt-3"
      @submit="editProduct"
    >
      <div class="modal-header">
        <h5 class="modal-title" id="ModalProductLabel">
          {{ "Modifier votre page d'accueil" }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <h6 for="formFile2">Séléctionner 4 produits</h6>
          <p class="info">
            <span>ctrl + click pour séléctionner des produits</span>
          </p>
          <select
            name="home_product"
            v-model="selected"
            class="form-select"
            required
            multiple
          >
            <option
              v-for="product in productlist"
              :key="product.id"
              :value="product"
            >
              {{ product.name }}
            </option>
          </select>
          <p v-if="this.selected.length == 0" class="info">
            <span style="color: red">{{ "0 produit sur 4 séléctionné" }}</span>
          </p>
          <p v-if="this.selected.length == 1" class="info">
            <span style="color: red">{{
              "1 produit sur 4 sélectionné: ( " + this.selected[0].name + " )"
            }}</span>
          </p>
          <p v-if="this.selected.length == 2" class="info">
            <span style="color: red">{{
              "2 produits sur 4 sélectionné: ( " +
              this.selected[0].name +
              ", " +
              this.selected[1].name +
              " )"
            }}</span>
          </p>
          <p v-if="this.selected.length == 3" class="info">
            <span style="color: red">{{
              "3 produits sur 4 sélectionné : ( " +
              this.selected[0].name +
              ", " +
              this.selected[1].name +
              ", " +
              this.selected[2].name +
              " )"
            }}</span>
          </p>
          <p v-if="this.selected.length == 4" class="info">
            <span style="color: green">{{
              "4 produits sur 4 sélectionné : ( " +
              this.selected[0].name +
              ", " +
              this.selected[1].name +
              ", " +
              this.selected[2].name +
              ", " +
              this.selected[3].name +
              " )"
            }}</span>
          </p>
          <p v-if="this.selected.length > 4" class="info">
            <span style="color: red">{{
              "Plus de 4 produits sont sélectionné."
            }}</span>
          </p>
        </div>
        <div class="mb-3">
          <h6 for="formFile2">Image d'accueil</h6>
          <div
            style="
              align-items: center;
              align-content: center;
              justify-content: center;
            "
          >
            <button
              type="button"
              @click="onFileClick('file2')"
              style="width: 100%; border-radius: 5px; border: none"
              class="btn btn-success"
              v-if="homebanner2 == null"
            >
              Choisir un fichier
            </button>
            <div
              v-else
              style="
                height: 10%;
                width: 100%;
                justify-content: center;
                display: flex;
                align-items: center;
              "
            >
              <div style="width: 20%; position: relative">
                <img
                  :src="homebanner2Url"
                  style="width: 100%; border-radius: 5px"
                  v-if="homebanner2.type.includes('image')"
                />
                <video
                  :src="homebanner2Url"
                  style="width: 100%; border-radius: 5px"
                  v-if="homebanner2.type.includes('video')"
                  autoplay
                  loop
                  muted
                ></video>
                <div
                  style="position: absolute; top: 0%; right: 0%; border: none"
                >
                  <button
                    type="button"
                    style="border: none; background: none"
                    @click="onFileClick('file2')"
                  >
                    <i
                      class="fas fa-pencil-alt text-dark"
                      aria-hidden="true"
                      color="grey"
                    ></i>
                  </button>
                  <button
                    type="button"
                    style="border: none; background: none"
                    @click="onFileDelete('file2')"
                  >
                    <i
                      class="far fa-trash-alt text-danger"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
            <input
              type="file"
              style="display: none"
              name="HomeBanner"
              id="formFile2"
              @input="this.updateInputValue"
              ref="file2"
            />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-success">Modifier</button>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Fermer
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import VmdInput from "./VmdInput.vue";
import axios from "axios";
export default {
  components: { VmdInput },
  props: {
    HomeId: Number,
  },
  name: "productCreateModal",
  mounted() {
    this.token = JSON.parse(localStorage.user).token;
    this.getProduct();
  },
  data: function () {
    return {
      prodname: "",
      barcode: "",
      token: "",
      homebanner: null,
      selected: [],
      productlist: [],
      homebanner2: null,
      homebanner2Url: null,
    };
  },
  methods: {
    onFileDelete(ref) {
      if (ref === "file2") {
        this.homebanner2 = null;
        this.homebanner2Url = null;
        URL.revokeObjectURL(this.homebanner2Url);
      }
    },
    onFileClick(ref) {
      this.$refs[ref].click();
    },
    async getProduct() {
      var Axiosheader = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + this.token,
        },
      };

      let response = await axios.get(
        `${this.$store.state.backendUrl}product/`,
        Axiosheader
      );
      this.productlist = response.data;
    },
    updateInputValue(event) {
      if (event.target.id === "formFile2") {
        if (this.homebanner2Url !== null) {
          URL.revokeObjectURL(this.homebanner2Url);
        }
        this.homebanner2 = event.target.files[0];
        this.homebanner2Url = URL.createObjectURL(this.homebanner2);
      }
    },
    editProduct(event) {
      event.preventDefault();
      let str = "";
      if (this.selected.length != 4) {
        alert("Veuillez sélectionner 4 produits.");
      } else {
        this.selected.map((prod, index) => {
          str = str + prod.id;
          if (index < this.selected.length - 1) {
            str = str + ",";
          }
        });
        const data = new FormData();
        var Axiosheader = {
          headers: { Authorization: "token " + this.token },
        };
        data.append("home_product", str);
        if (this.homebanner2) {
          data.append("home_banner", this.homebanner2);
          // console.log(data)
        }
        axios
          .patch(
            `${this.$store.state.backendUrl}home/` + this.HomeId,
            data,
            Axiosheader
          )
          .then((response) => {
            this.products = response.data;
            // console.log(response);
            this.$emit("homeedited");
          });
      }
    },
  },
};
</script>
