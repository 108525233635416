<template>
  <div class="mb-2" :class="directionReverse ? reverseDirection : ''">
    <div class="card-header p-3 pt-2" style="border-radius: 0.75rem 0.75rem 0.75rem 0.75rem;">
      <div
        class="icon icon-lg icon-shape shadow-dark shadow text-center border-radius-xl mt-n4 position-absolute"
        :class="iconBackground"
      >
        <i
          class="material-icons opacity-10"
          :class="iconClass"
          aria-hidden="true"
          >{{ iconName }}</i
        >
      </div>
      <div
        class="pt-1"
        :class="this.$store.state.isRTL ? 'text-start' : 'text-end'"
      >
        <p class="text-sm mb-0">{{ title }}</p>
        <h6 class="mb-0">{{ value }}</h6>
      </div>
    </div>
<!--    <div-->
<!--      class="card-footer p-3"-->
<!--      :class="this.$store.state.isRTL ? 'text-start' : 'text-end'"-->
<!--    ></div>-->
  </div>
</template>

<script>
export default {
  name: "mini-cards",
  data() {
    return {
      reverseDirection: "flex-row-reverse justify-content-between",
    };
  },
  props: {
    directionReverse: Boolean,
    title: {
      type: String,
      required: true,
    },
    value: {
      required: true,
    },
    percentage: String,
    iconName: {
      type: String,
      required: true,
    },
    iconClass: {
      type: String,
      required: true,
    },
    iconBackground: {
      type: String,
      default: "bg-white",
    },
  },
};
</script>
