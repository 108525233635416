<template>
  <div class="modal-content">
    <form
      label="Catégorie:"
      role="form"
      class="text-start mt-3"
      @submit="createProduct"
    >
      <div class="modal-header">
        <h5 class="modal-title" id="ModalProductLabel">
          Créer votre nouveau produit
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <select v-model="this.prodId" name="mainCategorie" class="form-select">
          <option selected value="0">
            {{ "ㅤSéléctionner une catégorie parent" }}
          </option>
          <option
            v-for="(category, index) in this.completelist"
            v-bind:value="category.id"
            :key="index"
          >
            >{{ category.name }}
          </option>
        </select>
        <p v-if="this.validProdId == false" class="error">
          <span style="color: red"
            >Vous devez obligatoirement sélectionner une catégorie.</span
          >
        </p>
        <br v-if="this.validProdId == true" />
        <div class="mb-3">
          <vmd-input
            id="NameProduct"
            type="string"
            label="Nom du produit"
            name="ProductName"
            @input="this.updateInputValue"
          ></vmd-input>
          <p v-if="this.validProdname == false" class="error">
            <span style="color: red">Ce champs est requis.</span>
          </p>
        </div>
        <div class="mb-3">
          <vmd-input
            id="CodeProduct"
            type="string"
            label="Code du produit"
            name="ProductCode"
            @input="this.updateInputValue"
          ></vmd-input>
          <p v-if="this.validBarcode == false" class="error">
            <span style="color: red">Ce champs est requis.</span>
          </p>
        </div>
        <div class="mb-3">
          <h6 for="formFile1">Fiche Produit</h6>
          <div
            style="
              align-items: center;
              align-content: center;
              justify-content: center;
            "
          >
            <button
              type="button"
              @click="onFileClick('file1')"
              style="width: 100%; border-radius: 5px; border: none"
              class="btn btn-success"
              v-if="proddetail == null"
            >
              Choisir un fichier
            </button>
            <div
              v-else
              style="
                height: 10%;
                width: 100%;
                justify-content: center;
                display: flex;
                align-items: center;
              "
            >
              <div style="width: 20%; position: relative">
                <img
                  :src="proddetailUrl"
                  style="width: 100%; border-radius: 5px"
                  v-if="proddetail.type.includes('image')"
                />
                <div
                  style="position: absolute; top: 0%; right: 0%; border: none"
                >
                  <button
                    type="button"
                    style="border: none; background: none"
                    @click="onFileClick('file1')"
                  >
                    <i
                      class="fas fa-pencil-alt text-dark"
                      aria-hidden="true"
                      color="grey"
                    ></i>
                  </button>
                  <button
                    type="button"
                    style="border: none; background: none"
                    @click="onFileDelete('file1')"
                  >
                    <i
                      class="far fa-trash-alt text-danger"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
            <input
              type="file"
              style="display: none"
              name="ProductDetails"
              id="formFile1"
              @input="this.updateInputValue"
              ref="file1"
            />
          </div>
          <p v-if="this.validProddetail === false" class="error">
            <span style="color: red">Vous devez choisir un fichier image.</span>
          </p>
        </div>
        <div class="mb-3">
          <h6 for="formFile2">Bannière Produit</h6>
          <div
            style="
              align-items: center;
              align-content: center;
              justify-content: center;
            "
          >
            <button
              type="button"
              @click="onFileClick('file2')"
              style="width: 100%; border-radius: 5px; border: none"
              class="btn btn-success"
              v-if="prodbanner == null"
            >
              Choisir un fichier
            </button>
            <div
              v-else
              style="
                height: 10%;
                width: 100%;
                justify-content: center;
                display: flex;
                align-items: center;
              "
            >
              <div style="width: 20%; position: relative">
                <img
                  :src="prodbannerUrl"
                  style="width: 100%; border-radius: 5px"
                  v-if="prodbanner.type.includes('image')"
                />
                <video
                  :src="prodbannerUrl"
                  style="width: 100%; border-radius: 5px"
                  v-if="prodbanner.type.includes('video')"
                  autoplay
                  loop
                  muted
                ></video>
                <div
                  style="position: absolute; top: 0; right: 0; border: none"
                >
                  <button
                    type="button"
                    style="border: none; background: none"
                    @click="onFileClick('file2')"
                  >
                    <i
                      class="fas fa-pencil-alt text-dark"
                      aria-hidden="true"
                      color="grey"
                    ></i>
                  </button>
                  <button
                    type="button"
                    style="border: none; background: none"
                    @click="onFileDelete('file2')"
                  >
                    <i
                      class="far fa-trash-alt text-danger"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
            <input
              type="file"
              style="display: none"
              name="ProductBanner"
              id="formFile2"
              @input="this.updateInputValue"
              ref="file2"
            />
          </div>
          <p v-if="this.validProdbanner == false" class="error">
            <span style="color: red">Vous devez choisir un fichier image.</span>
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" @click="createProduct" class="btn btn-success">
          Créer
        </button>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Fermer
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import VmdInput from "./VmdInput.vue";
import axios from "axios";
import { nextTick } from "vue";
export default {
  components: { VmdInput },
  props: {
    CategoryList: Array,
    modalRef: Object,
  },
  mounted() {
    this.token = JSON.parse(localStorage.user).token;
    this.setAllCategory(this.CategoryList);
    // console.log(this.CategoryList);
    // console.log("FULL LIST = ",this.completelist)
  },
  name: "productCreateModal",
  data: function () {
    return {
      // categname: '',
      prodname: "",
      barcode: "",
      token: "",
      proddetail: null,
      proddetailUrl: null,
      prodbanner: null,
      prodbannerUrl: null,
      homeimage: null,
      completelist: [],
      prodId: 0,
      tmp: {},
      validFields: false,
      validBarcode: true,
      validProdname: true,
      validProdId: true,
      validProddetail: true,
      validProdbanner: true,
    };
  },
  methods: {
    onFileDelete(ref) {
      if (ref === "file1") {
        URL.revokeObjectURL(this.proddetailUrl);
        this.proddetail = null;
        this.proddetailUrl = null;
      } else if (ref === "file2") {
        URL.revokeObjectURL(this.prodbannerUrl);
        this.prodbanner = null;
        this.prodbannerUrl = null;
      }
    },
    onFileClick(ref) {
      this.$refs[ref].click();
    },
    setAllCategory(categorylist) {
      // console.log("FULL LIST", categorylist)
      categorylist.map((category) => {
        this.completelist = this.completelist.concat(category);
        // console.log(this.completelist)
        if (category.children.length > 0) {
          this.setAllCategory(category.children);
        }
      });
    },
    updateInputValue(event) {
      if (event.target.id === "NameProduct") {
        this.prodname = event.target.value;
      }
      if (event.target.id === "CodeProduct") {
        this.barcode = event.target.value;
        // console.log(this.barcode);
      }
      if (event.target.id === "formFile1") {
        if (!event.target.files[0].type.includes("image")) {
          alert("Fichier non supporté");
          URL.revokeObjectURL(this.proddetailUrl);
          this.proddetail = null;
          this.proddetailUrl = null;
          return;
        }
        if (this.proddetailUrl !== null) {
          URL.revokeObjectURL(this.proddetailUrl);
        }
        this.proddetail = event.target.files[0];
        this.proddetailUrl = URL.createObjectURL(this.proddetail);
      }
      if (event.target.id === "formFile2") {
        if (this.prodbannerUrl !== null) {
          URL.revokeObjectURL(this.prodbannerUrl);
        }
        this.prodbanner = event.target.files[0];
        this.prodbannerUrl = URL.createObjectURL(this.prodbanner);
      }
      // console.log(this.prodname);
    },
    checkFormField() {
      if (this.prodId == 0) {
        this.validProdId = false;
      } else {
        this.validProdId = true;
      }
      if (this.prodname == "") {
        this.validProdname = false;
      } else {
        this.validProdname = true;
      }
      if (this.barcode == "") {
        this.validBarcode = false;
      } else {
        this.validBarcode = true;
      }
      if (this.proddetail == null) {
        this.validProddetail = false;
      } else {
        this.validProddetail = true;
      }
      if (this.prodbanner == null) {
        this.validProdbanner = false;
      } else {
        this.validProdbanner = true;
      }
      if (
        this.validProdId == true &&
        this.validProdname == true &&
        this.validBarcode == true &&
        this.validProddetail == true &&
        this.validProdbanner == true
      ) {
        this.validFields = true;
      }
    },
    createProduct(event) {
      event.preventDefault();
      this.checkFormField();
      //   console.log(event.target.elements.mainCategorie.value)

      if (this.validFields == true) {
        const data = new FormData();
        var Axiosheader = {
          headers: { Authorization: "token " + this.token },
        };
        data.append("category", this.prodId);
        data.append("name", this.prodname);
        data.append("barcode", this.barcode);
        data.append("product_details", this.proddetail);
        data.append("product_banner", this.prodbanner);
        // if (event.currentTarget.elements.HomepageImage.files[0]) {
        //   data.append(
        //     "homepage_image",
        //     event.currentTarget.elements.HomepageImage.files[0]
        //   );
        // }
        // data.append()
        // console.log(data)
        axios
          .post(`${this.$store.state.backendUrl}product/`, data, Axiosheader)
          .then((response) => {
            // if (response.status == 400) {
            //   alert("Veuillez verifier votre code bar");
            // }
            this.$emit("productcreated");
            // console.log(response.status);
          })
          .catch((error) => {
            alert("Veuillez verifier votre code bar");
            // console.log(error);
          });
      }
    },
  },
};
</script>
