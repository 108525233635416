<template>
  <div class="modal-content">
    <div class="modal-header">
      <h6 class="modal-title" id="ModalBannerLabel">{{ this.ProductName }}</h6>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      ></button>
    </div>
    <div
      class="modal-body"
      style="display: flex; align-items: center; flex-direction: column"
    >
      <video
        type="video/mp4"
        v-if="this.ImageUrl.includes('.mp4')"
        v-bind:src="this.ImageUrl"
        style="max-height: 474px; max-width: 757px"
        controls
        autoplay
        ref="videoRef"
      />
      <img
        v-if="!this.ImageUrl.includes('.mp4')"
        v-bind:src="this.ImageUrl"
        style="max-height: 474px; max-width: 757px"
        alt="bannerImage"
      />
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
        @click="stopVideo"
      >
        Fermer
      </button>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.modalRef.addEventListener("hide.bs.modal", () => this.stopVideo());
    this.modalRef.addEventListener("show.bs.modal", () => {
      this.$refs.videoRef?.load();
      this.$refs.videoRef?.play();
    });
  },
  name: "bannerModal",
  props: {
    ProductName: String,
    ImageUrl: String,
    modalRef: Object,
  },
  methods: {
    stopVideo() {
      // if isvideo / not image
      this.$refs.videoRef?.pause();
    },
  },
};
</script>

<style></style>
