<template>
  <div class="py-4 container-fluid">
    <div class="row mb-4">
      <div class="col-lg-12 position-relative z-index-2">
        <div id="minicards" v-if="dashboardMiniCardDataReady" class="row">
          <div class="col-lg-4 col-md-6 col-sm-6">
            <mini-cards
              title="Utilisation générale"
              :value="scanTypesDisplay"
              iconName="bar_chart"
              iconClass="text-white"
              iconBackground="bg-gradient-dark"
            />
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <mini-cards
              title="Total des magasins"
              :value="stores"
              iconName="store"
              iconClass="text-white"
              iconBackground="bg-gradient-primary"
            />
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <mini-cards
              title="Total des écrans"
              :value="screenUseDisplay"
              valueColor="text-danger"
              iconName="smartphone"
              iconClass="text-white"
              iconBackground="bg-gradient-success"
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-sm-6 col-lg-7 col-md-12"></div>
          <div class="col-sm-6 col-lg-8 col-md-8 mt-lg-0 mt-md-4">
            <chart
              v-if="productDatasetsReady"
              id="chart-product"
              title="Répartition par produit"
              type="bar"
              :datasets="productDatasets"
              :statType="selectedStatType"
              :labels="productStatLabels"
              :options="productChartOptions"
            />
          </div>
          <div class="col-sm-6 col-lg-4 col-md-4 mt-lg-0 mt-md-4">
            <chart
              v-if="categoryDatasetsReady"
              id="chart-categories"
              title="Répartition par catégorie"
              type="pie"
              :datasets="categoryDatasets"
              :statType="selectedStatType"
              :labels="categoryStatLabels"
              :options="categoryChartOptions"
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-sm-6 col-lg-12 col-md-12 mt-lg-0 mt-md-4">
            <chart
              v-if="categoryDatasetsReady"
              id="chart-activity"
              title="Répartition par heure"
              type="line"
              :datasets="activityDatasets"
              :statType="selectedStatType"
              :labels="activityStatLabels"
              :options="activityChartOptions"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { mapState } from "vuex";

import Chart from "./components/Chart.vue";
import MiniCards from "./components/MiniCards.vue";

export default {
  name: "dashboard-default",
  components: {
    Chart,
    MiniCards,
  },
  setup() {
    return {
      miniCardsData: {},
      miniCardsDataReady: false,
      overallScans: ref({}),
      screenUse: ref({}),
      stores: ref({}),
      // chartData,
      // chartDataReady,
    };
  },
  mounted() {
    // console.log("mounted dashboard");
    this.$store.dispatch("dashboard/initDashboardMiniCardData");
    this.generateDashboardQueryParams();
    this.$store.dispatch("stats/initStatData");
  },
  methods: {
    generateDashboardQueryParams() {
      let queryParams = `type=${this.$store.state.stats.selectedStatType}`;

      this.$store.commit("stats/setQueryParams", queryParams);
    },
  },
  computed: {
    ...mapState(["backendUrl"]),
    ...mapState("stats", [
      "queryParams",
      "statDataReady",
      "statStoreListReady",
      "selectedStore",
      "statDeviceListReady",
      "selectedDevice",
      "selectedStatType",
    ]),
    ...mapState("stats/products", {
      productStatLabels: (state) => state.productStatLabels,
      productDatasets: (state) => state.productDatasets,
      productDatasetsReady: (state) => state.productDatasetsReady,
      productChartOptions: (state) => state.productChartOptions,
    }),
    ...mapState("stats/categories", {
      categoryStatLabels: (state) => state.categoryStatLabels,
      categoryDatasets: (state) => state.categoryDatasets,
      categoryDatasetsReady: (state) => state.categoryDatasetsReady,
      categoryChartOptions: (state) => state.categoryChartOptions,
    }),
    ...mapState("stats/activity", {
      activityStatLabels: (state) => state.activityStatLabels,
      activityDatasets: (state) => state.activityDatasets,
      activityDatasetsReady: (state) => state.activityDatasetsReady,
      activityChartOptions: (state) => state.activityChartOptions,
    }),
    ...mapState("dashboard", [
      "dashboardMiniCardData",
      "dashboardMiniCardDataReady",
    ]),
    scanTypesDisplay() {
      let { total, press, scan } = this.overallScans;
      return `${total} dont ${press} appuis et ${scan} scans`;
    },
    screenUseDisplay() {
      let { total, used } = this.screenUse;
      return `${total} écrans dont ${used} utilisés`;
    },
    ...mapState(["filters", "backendUrl"]),
    ...mapState("stats", [
      "statDataReady",
      "statStoreListReady",
      "selectedStore",
      "statDeviceListReady",
      "selectedDevice",
      "selectedStatType",
    ]),
    ...mapState("stats/products", {
      productStatLabels: (state) => state.productStatLabels,
      productDatasets: (state) => state.productDatasets,
      productDatasetsReady: (state) => state.productDatasetsReady,
      productChartOptions: (state) => state.productChartOptions,
    }),
    ...mapState("stats/categories", {
      categoryStatLabels: (state) => state.categoryStatLabels,
      categoryDatasets: (state) => state.categoryDatasets,
      categoryDatasetsReady: (state) => state.categoryDatasetsReady,
      categoryChartOptions: (state) => state.categoryChartOptions,
    }),
    ...mapState("stats/activity", {
      activityStatLabels: (state) => state.activityStatLabels,
      activityDatasets: (state) => state.activityDatasets,
      activityDatasetsReady: (state) => state.activityDatasetsReady,
      activityChartOptions: (state) => state.activityChartOptions,
    }),
  },
  watch: {
    dashboardMiniCardData(newData) {
      // console.log("mini card data UPDARE", newData, oldData);
      this.overallScans = newData["overall_scans"];
      this.screenUse = newData["screen_use"];
      this.stores = newData["stores"];
      // console.log(newData["overall_scans"]);
    },
  },
};
</script>
