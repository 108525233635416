<template>
  <div class="modal-content">
    <form
      label="Produit:"
      role="form"
      class="text-start mt-3"
      @submit="editProduct"
    >
      <div class="modal-header">
        <h5 class="modal-title" id="ModalProductLabel">
          {{ "Modifier votre produit: " + this.ProductName }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <select
            v-model="this.prodId"
            name="mainCategorie"
            class="form-select"
          >
            <option selected value="0">
              {{ "ㅤModifier la catégorie parent ?" }}
            </option>
            <option
              v-for="(category, index) in this.completelist"
              v-bind:value="category.id"
              :key="index"
            >
              >{{ category.name }}
            </option>
          </select>
          <br />
          <h6 for="formFile2">{{ "Nom du produit" }}</h6>
          <vmd-input
            id="NameProduct"
            type="string"
            name="ProductName"
            :value="this.ProductName"
            @input="this.updateInputValue"
          ></vmd-input>
        </div>
        <div class="mb-3">
          <h6 for="formFile2">{{ "Code du produit" }}</h6>
          <vmd-input
            id="CodeProduct"
            type="string"
            name="ProductCode"
            :value="this.ProductBarcode"
            @input="this.updateInputValue"
          ></vmd-input>
        </div>
        <div class="mb-3">
          <h6 for="formFile1">Fiche Produit</h6>
          <div
            style="
              align-items: center;
              align-content: center;
              justify-content: center;
            "
          >
            <button
              type="button"
              @click="onFileClick('file1')"
              style="width: 100%; border-radius: 5px; border: none"
              class="btn btn-success"
              v-if="proddetail == null && proddetailUrl == null"
            >
              Choisir un fichier
            </button>
            <div
              v-else
              style="
                height: 10%;
                width: 100%;
                justify-content: center;
                display: flex;
                align-items: center;
              "
            >
              <div style="width: 20%; position: relative">
                <img
                  :src="proddetailUrl"
                  style="width: 100%; border-radius: 5px"
                  v-if="
                    isImage(proddetailUrl) || proddetail?.type.includes('image')
                  "
                />
                <div
                  style="position: absolute; top: 0%; right: 0%; border: none"
                >
                  <button
                    type="button"
                    style="border: none; background: none"
                    @click="onFileClick('file1')"
                  >
                    <i
                      class="fas fa-pencil-alt text-dark"
                      aria-hidden="true"
                      color="grey"
                    ></i>
                  </button>
                  <button
                    type="button"
                    style="border: none; background: none"
                    @click="onFileDelete('file1')"
                  >
                    <i
                      class="far fa-trash-alt text-danger"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
            <input
              type="file"
              style="display: none"
              name="ProductDetails"
              id="formFile1"
              @input="this.updateInputValue"
              ref="file1"
            />
          </div>
        </div>
        <div class="mb-3">
          <h6 for="formFile2">Bannière Produit</h6>
          <div
            style="
              align-items: center;
              align-content: center;
              justify-content: center;
            "
          >
            <button
              type="button"
              @click="onFileClick('file2')"
              style="width: 100%; border-radius: 5px; border: none"
              class="btn btn-success"
              v-if="prodbanner == null && prodbannerUrl == null"
            >
              Choisir un fichier
            </button>
            <div
              v-else
              style="
                height: 10%;
                width: 100%;
                justify-content: center;
                display: flex;
                align-items: center;
              "
            >
              <div style="width: 20%; position: relative">
                <img
                  :src="prodbannerUrl"
                  style="width: 100%; border-radius: 5px"
                  v-if="
                    isImage(prodbannerUrl) || prodbanner?.type.includes('image')
                  "
                />
                <video
                  :src="prodbannerUrl"
                  style="width: 100%; border-radius: 5px"
                  v-if="
                    isVideo(prodbannerUrl) || prodbanner?.type.includes('video')
                  "
                  autoplay
                  loop
                  muted
                  ref="videoRef"
                ></video>
                <div
                  style="position: absolute; top: 0%; right: 0%; border: none"
                >
                  <button
                    type="button"
                    style="border: none; background: none"
                    @click="onFileClick('file2')"
                  >
                    <i
                      class="fas fa-pencil-alt text-dark"
                      aria-hidden="true"
                      color="grey"
                    ></i>
                  </button>
                  <button
                    type="button"
                    style="border: none; background: none"
                    @click="onFileDelete('file2')"
                  >
                    <i
                      class="far fa-trash-alt text-danger"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
            <input
              type="file"
              style="display: none"
              name="ProductBanner"
              id="formFile2"
              @input="this.updateInputValue"
              ref="file2"
            />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="submit"
          @click="editProduct"
          class="btn btn-success"
          data-bs-dismiss="modal"
        >
          Modifier
        </button>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Fermer
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import VmdInput from "./VmdInput.vue";
import axios from "axios";
export default {
  components: { VmdInput },
  props: {
    ProductName: String,
    ProductBarcode: String,
    ProductImage: String,
    CategoryList: Array,
    Product: Object,
    modalRef: Object,
  },
  mounted() {
    this.token = JSON.parse(localStorage.user).token;
    this.setAllCategory(this.CategoryList);
    this.proddetailUrl = this.Product.product_details;
    this.prodbannerUrl = this.Product.product_banner;
    this.$refs.videoRef?.load();
  },
  watch: {
    Product: function(newValue) {
      this.prodbannerUrl = newValue.product_banner;
      this.$refs.videoRef?.load();
    },
  },
  name: "productCreateModal",
  data: function () {
    return {
      prodname: "",
      token: "",
      barcode: "",
      homeimage: "",
      proddetail: null,
      proddetailUrl: null,
      prodbanner: null,
      prodbannerUrl: null,
      completelist: [],
      prodId: 0,
      tmp: {},
    };
  },
  methods: {
    isVideo(url) {
      return /\.(webm|mkv|flv|vob|ogv|ogg|avi|mov|mp4|m4v|m3a)$/.test(url);
    },
    isImage(url) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
    },
    onFileDelete(ref) {
      if (ref === "file2") {
        URL.revokeObjectURL(this.prodbannerUrl);
        this.prodbanner = null;
        this.prodbannerUrl = null;
      }
      if (ref === "file1") {
        URL.revokeObjectURL(this.proddetailUrl);
        this.proddetail = null;
        this.proddetailUrl = null;
      }
    },
    onFileClick(ref) {
      this.$refs[ref].click();
    },
    updateInputValue(event) {
      if (event.target.id === "NameProduct") {
        this.prodname = event.target.value;
      }
      if (event.target.id === "CodeProduct") {
        this.barcode = event.target.value;
      }
      if (event.target.id === "formFile1") {
        if (!event.target.files[0].type.includes("image")) {
          alert("Fichier non supporté");
          URL.revokeObjectURL(this.proddetailUrl);
          this.proddetail = null;
          this.proddetailUrl = null;
          return;
        }
        if (this.proddetailUrl !== null) {
          URL.revokeObjectURL(this.proddetailUrl);
        }
        this.proddetail = event.target.files[0];
        this.proddetailUrl = URL.createObjectURL(this.proddetail);
      }
      if (event.target.id === "formFile2") {
        if (this.prodbannerUrl !== null) {
          URL.revokeObjectURL(this.prodbannerUrl);
        }
        this.prodbanner = event.target.files[0];
        this.prodbannerUrl = URL.createObjectURL(this.prodbanner);
      }
      // console.log(this.prodname);
    },
    setAllCategory(categorylist) {
      // console.log("FULL LIST", categorylist)
      categorylist.map((category) => {
        this.completelist = this.completelist.concat(category);
        // console.log(this.completelist)
        if (category.children.length > 0) {
          this.setAllCategory(category.children);
        }
      });
    },
    editProduct(event) {
      event.preventDefault();
      //   console.log(event.target.elements.mainCategorie.value)
      const data = new FormData();
      var Axiosheader = {
        headers: { Authorization: "token " + this.token },
      };
      if (this.prodId != 0) {
        data.append("category", this.prodId);
      }
      if (this.prodname) {
        data.append("name", this.prodname);
      }
      if (this.barcode) {
        data.append("barcode", this.barcode);
      }
      if (this.prodbanner) {
        data.append("product_banner", this.prodbanner);
      }
      if (this.proddetail) {
        data.append("product_details", this.proddetail);
      }
      // await this.editProductImages(event);
      // console.log(data);
      axios
        .patch(
          `${this.$store.state.backendUrl}product/edit/` + this.ProductBarcode,
          data,
          Axiosheader
        )
        .then((response) => {
          // this.products = response.fields;
          this.$emit("productedited");
        });
    },
  },
};
</script>
