<template>
  <div class="modal-content">
    <form
      label="Produit:"
      role="form"
      class="text-start mt-3"
      @submit="editProduct"
    >
      <div class="modal-header">
        <h5 class="modal-title" id="ModalProductLabel">
          {{ "Modifier l'image d'accueil du produit: " + this.ProductName }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <h6 for="formFile3">Image de la page d'accueil</h6>
          <!-- <vmd-input type="file" name="HomepageImage" v-model="this.homeimage" id="formFile3"></vmd-input> -->
          <div
            style="
              align-items: center;
              align-content: center;
              justify-content: center;
            "
          >
            <button
              type="button"
              @click="onFileClick('file1')"
              style="width: 100%; border-radius: 5px; border: none"
              class="btn btn-success"
              v-if="homeimage2 == null"
            >
              Choisir un fichier
            </button>
            <div
              v-else
              style="
                height: 10%;
                width: 100%;
                justify-content: center;
                display: flex;
                align-items: center;
              "
            >
              <div style="width: 20%; position: relative">
                <img
                  :src="homeimage2Url"
                  style="width: 100%; border-radius: 5px"
                  v-if="homeimage2.type.includes('image')"
                />
                <div style="position: absolute; top: 0; right: 0; border: none">
                  <button
                    type="button"
                    style="border: none; background: none"
                    @click="onFileClick('file1')"
                  >
                    <i
                      class="fas fa-pencil-alt text-dark"
                      aria-hidden="true"
                      color="grey"
                    ></i>
                  </button>
                  <button
                    type="button"
                    style="border: none; background: none"
                    @click="onFileDelete('file1')"
                  >
                    <i
                      class="far fa-trash-alt text-danger"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
            <input
              type="file"
              style="display: none"
              name="ProductDetails"
              id="formFile3"
              @input="this.updateInputValue"
              ref="file1"
            />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" @click="editProduct" class="btn btn-success">
          Modifier
        </button>
        <button
          type="button"
          @click="console.log(this.tmp)"
          class="btn btn-secondary"
          data-bs-dismiss="modal"
        >
          Fermer
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import VmdInput from "./VmdInput.vue";
import axios from "axios";
export default {
  components: { VmdInput },
  props: {
    ProductName: String,
    ProductBarcode: String,
    ProductImage: String,
  },
  mounted() {
    this.token = JSON.parse(localStorage.user).token;
  },
  name: "productCreateModal",
  data: function () {
    return {
      prodname: "",
      token: "",
      barcode: "",
      homeimage: "",
      homeimage2: null,
      homeimage2Url: null,
      tmp: {},
    };
  },
  methods: {
    onFileDelete(ref) {
      if (ref === "file1") {
        URL.revokeObjectURL(this.homeimage2Url);
        this.homeimage2 = null;
        this.homeimage2Url = null;
      }
    },
    onFileClick(ref) {
      this.$refs[ref].click();
    },
    updateInputValue(event) {
      if (event.target.id === "formFile3") {
        if (!event.target.files[0].type.includes("image")) {
          alert("Fichier non supporté");
          URL.revokeObjectURL(this.homeimage2Url);
          this.homeimage2 = null;
          this.homeimage2Url = null;
          return;
        }
        if (this.homeimage2Url !== null) {
          URL.revokeObjectURL(this.homeimage2Url);
        }
        this.homeimage2 = event.target.files[0];
        this.homeimage2Url = URL.createObjectURL(this.homeimage2);
      }
    },
    editProduct(event) {
      event.preventDefault();
      //   console.log(event.target.elements.mainCategorie.value)
      const data = new FormData();
      var Axiosheader = {
        headers: { Authorization: "token " + this.token },
      };
      if (!this.homeimage2) {
        alert("Veuillez choisir une nouvelle image pour modifier");
      } else {
        data.append("homepage_image", this.homeimage2);
        // console.log(data);
        axios
          .patch(
            `${this.$store.state.backendUrl}product/edit/` +
              this.ProductBarcode,
            data,
            Axiosheader
          )
          .then((response) => {
            // this.products = response.data.fields;
            this.$emit("homeimageedited");
            // console.log(response);
          });
      }
    },
  },
};
</script>
