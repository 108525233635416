/* eslint-disable*/
import axios from "axios";

export default {
    state: {
        noStats: false,
        queryParams: null,
        statData: null,
        statDataReady: false,


        statStoreList: null,
        statStoreListReady: false,

        statDeviceList: null,
        statDeviceListReady: false,

        statTypeList: [
            "scan",
            "press"
        ],

        selectedDateRange: null,
        selectedStore: null,
        selectedDevice: null,
        selectedStatType: "scan",
    },
    mutations: {
        noStats(state, bool) {
            state.noStats = bool;
        },
        statData(state, { data, ready }) {
            // console.log('STAT DATA BEFORE', state.statData)
            (data.length === 0) ? state.noStats = true : state.noStats = false;
            state.statData = data;
            // console.log('STAT DATA after', state.statData)
            state.statDataReady = ready;
        },
        statStoreList(state, { data, ready }) {
            state.statStoreList = data;
            state.statStoreListReady = ready;
            state.selectedStore = data[0];
            // console.log('DEFAULT STORE', state.selectedStore)
        },
        statDeviceList(state, { data, ready }) {
            state.statDeviceList = data;
            state.statDeviceListReady = ready;
            // console.log('device list', state.statDeviceList)
            // console.log('DEFAULT device', state.selectedDevice)
        },
        setQueryParams(state, queryParams) {
            state.queryParams = queryParams
        },
        selectDateRange(state, dateRange) {
            // console.log('MUTATION DATE RANGE', dateRange)
            state.selectedDateRange = dateRange
        },
        selectDevice(state, device) {
            state.selectedDevice = device
        },
        selectStore(state, store) {
            state.selectedStore = store
            // console.log("DEPP", store)
            state.statDeviceList = store.store_devices
            state.selectedDevice = null
        },
        selectStatType(state, statType) {
            state.selectedStatType = statType
        },

    },
    actions: {
        initStatStoreList: async ({ commit, state, rootState, rootGetters }) => {
            let headers = {
                Authorization: "token " + JSON.parse(localStorage.user).token,
            };
            let res = await axios.get(`${rootState.backendUrl}store/`, { headers })
            let storeListMutation = rootGetters.setDataAndReady(res.data, true)
            let deviceListMutation = rootGetters.setDataAndReady(res.data[0].store_devices, true)
            commit("statStoreList", storeListMutation);
            commit("statDeviceList", deviceListMutation)
        },
        initStatData({ dispatch, commit, state, rootState, rootGetters }) {
            let options = {
                headers: { Authorization: "token " + JSON.parse(localStorage.user).token },
            };
            let statRequestUrl = `${rootState.backendUrl}stats?${state.queryParams}`
            axios.get(statRequestUrl, options).then((res) => {

                let mutationData = rootGetters.setDataAndReady(res.data, true)
                commit("statData", mutationData);
                // console.log("GROUP BY DEVICE: ", rootGetters.groupByKey(rootState.stats.statData, 'device', {
                //     omitKey: true
                // }))
                // console.log("GROUP BY CATEGORY: ", rootGetters.groupByKey(rootState.stats.statData, 'product', {
                //     omitKey: true, childKey: 'category'
                // }))
                dispatch('formatStatData');

            })
        },
        updateStats({ dispatch, commit, state, rootState, rootGetters }) {
            let options = {
                headers: { Authorization: "token " + JSON.parse(localStorage.user).token },
            };

            axios.get(`${rootState.backendUrl}stats?${state.queryParams}`, options).then((res) => {
                let mutationData = rootGetters.setDataAndReady(res.data, true)
                if (res.data == false) {
                    alert('Aucune statistique selon les paramètres de recherche sélectionnés')
                }
                commit("statData", mutationData);
                dispatch('formatStatData');

                // console.log("GROUP BY DEVICE: ", rootGetters.groupByKey(rootState.stats.statData, 'device', {
                //     omitKey: true
                // }))
                // console.log("GROUP BY CATEGORY: ", rootGetters.groupByKey(rootState.stats.statData, 'product', {
                //     omitKey: true, childKey: 'category'
                // }))
            })
        },
        formatStatData({ dispatch, commit, state, rootState, rootGetters }) {
            if (state.statDataReady) {
                dispatch("products/getProductsData");
                dispatch("categories/getCategoryData");
                dispatch("activity/getActivityData");
                // dispatch('products/getProducts')

                // dispatch('statFactory')
            }
        },

    },
    getters: {
        usedDevices: state => {
            return state.statDeviceList.filter(device => device.uuid != null)
        },
    },
    modules: {
        products: {
            namespaced: true,
            state: {
                productData: null,
                productDataReady: false,
                productCountStats: null,
                productCountStatsReady: false,
                productStatLabels: null,
                productDatasets: null,
                productDatasetsReady: false,
                groupKey: "product",
                childKey: "name",
                productChartOptions: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                    interaction: {
                        intersect: false,
                        mode: "index",
                    },

                }
            },

            actions: {
                getProductsData({ state, dispatch, commit, rootState, rootGetters }) {
                    // console.log('PRODUCT MODULE ACTION', rootState.stats.statData)
                    let data = rootGetters.groupByKey(rootState.stats.statData, state.groupKey, {
                        omitKey: true, childKey: state.childKey
                    })
                    // console.log('prod data', data)
                    let mutationData = rootGetters.setDataAndReady(data, true)
                    let counts = {};

                    commit("productData", mutationData);
                    const countsByType = (key, set) => {
                        let count = { press: 0, scan: 0 };
                        set.forEach((element) => {
                            if (element.stat_type == "scan") {
                                count.scan += 1;
                            } else if (element.stat_type == "press") {
                                count.press += 1;
                            }
                        });
                        return { [key]: count };
                    };
                    let products = Object.keys(state.productData)
                    products.forEach((groupKey) => {
                        Object.assign(counts, countsByType(groupKey, state.productData[groupKey]));
                    });
                    commit('productStatLabels', products)
                    commit('productCountStats', rootGetters.setDataAndReady(counts, true))
                    // console.log(counts)
                    dispatch('getProductsDatasets')
                },
                getProductsDatasets({ state, commit, rootGetters, rootState }) {
                    let arraysObj = {};
                    let statType = rootState.stats.selectedStatType;
                    let datasets = [];
                    arraysObj[statType] = [];
                    // console.log('STAT TYPE', statType)
                    // console.log('ARRAY OBJ', arraysObj)
                    // console.log('USED KEY', rootState.stats.selectedStatType)
                    Object.keys(state.productCountStats).forEach((key) => {
                        arraysObj[statType].push(state.productCountStats[key][statType])
                    });
                    datasets = Object.keys(arraysObj).map((key) => {
                        let { backgroundColor, borderColor } = rootGetters.generateRGBAStyle();
                        let obj = {
                            label: rootGetters.capitalize(key),
                            data: arraysObj[key],
                            backgroundColor: [backgroundColor],
                            borderColor: [borderColor],
                            borderWidth: 1,
                        };
                        return obj;
                    });

                    commit('productDatasets', rootGetters.setDataAndReady(datasets, true))
                    // console.log("SETS", state.productDatasets)
                },

            },
            mutations: {
                productData(state, { data, ready }) {
                    state.productData = data;
                    state.productDataReady = ready;
                },
                productCountStats(state, { data, ready }) {
                    state.productCountStats = data;
                    state.productCountStatsReady = ready;
                },
                productStatLabels(state, labels) {
                    state.productStatLabels = labels;
                },
                productDatasets(state, { data, ready }) {
                    state.productDatasets = data
                    state.productDatasetsReady = ready
                }
            }
        },

        categories: {
            namespaced: true,
            state: {
                categoryData: null,
                categoryDataReady: false,
                categoryCountStats: null,
                categoryCountStatsReady: false,
                categoryStatLabels: null,
                categoryDatasets: null,
                categoryDatasetsReady: false,
                groupKey: "root_category",
                childKey: "name",
                categoryChartOptions: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                    // interaction: {
                    //     intersect: false,
                    //     mode: "index",
                    // },

                }
            },

            actions: {
                getCategoryData({ state, dispatch, commit, rootState, rootGetters }) {
                    // console.log('PRODUCT MODULE ACTION', rootState.stats.statData)
                    let data = rootGetters.groupByKey(rootState.stats.statData, state.groupKey, {
                        omitKey: true, childKey: state.childKey
                    })
                    let mutationData = rootGetters.setDataAndReady(data, true)
                    let counts = {};

                    commit("categoryData", mutationData);
                    const countsByType = (key, set) => {
                        let count = { press: 0, scan: 0 };
                        set.forEach((element) => {
                            if (element.stat_type == "scan") {
                                count.scan += 1;
                            } else if (element.stat_type == "press") {
                                count.press += 1;
                            }
                        });
                        return { [key]: count };
                    };
                    let products = Object.keys(state.categoryData)
                    products.forEach((groupKey) => {
                        Object.assign(counts, countsByType(groupKey, state.categoryData[groupKey]));
                    });
                    commit('categoryStatLabels', products)
                    commit('categoryCountStats', rootGetters.setDataAndReady(counts, true))
                    dispatch('getCategoryDatasets')
                },
                getCategoryDatasets({ state, commit, rootGetters, rootState }) {
                    let arraysObj = {};
                    let statType = rootState.stats.selectedStatType;
                    let datasets = [];
                    arraysObj[statType] = [];
                    // console.log('USED KEY', rootState.stats.selectedStatType)
                    // console.log('ALT KEY', rootState.stats.selectedStatType)
                    Object.keys(state.categoryCountStats).forEach((key) => {
                        arraysObj[statType].push(state.categoryCountStats[key][statType])
                    });
                    datasets = Object.keys(arraysObj).map((key) => {
                        let { backgroundColor, borderColor } = rootGetters.generateRGBAStyle();
                        let obj = {
                            label: rootGetters.capitalize(key),
                            data: arraysObj[key],
                            backgroundColor: [backgroundColor],
                            borderColor: [borderColor],
                            borderWidth: 1,
                        };
                        return obj;
                    });

                    commit('categoryDatasets', rootGetters.setDataAndReady(datasets, true))
                },

            },
            mutations: {
                categoryData(state, { data, ready }) {
                    state.categoryData = data;
                    state.categoryDataReady = ready;
                },
                categoryCountStats(state, { data, ready }) {
                    state.categoryCountStats = data;
                    state.categoryCountStatsReady = ready;
                },
                categoryStatLabels(state, labels) {
                    state.categoryStatLabels = labels;
                },
                categoryDatasets(state, { data, ready }) {
                    state.categoryDatasets = data
                    state.categoryDatasetsReady = ready
                }
            }
        },

        activity: {
            namespaced: true,
            state: {
                activityData: null,
                activityDataReady: false,
                activityCountStats: null,
                activityCountStatsReady: false,
                activityStatLabels: null,
                activityDatasets: null,
                activityDatasetsReady: false,
                groupKey: "round_time",
                activityChartOptions: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                    // interaction: {
                    //     intersect: false,
                    //     mode: "index",
                    // },

                }

            },

            actions: {
                getActivityData({ state, dispatch, commit, getters, rootState, rootGetters }) {
                    let data = rootGetters.groupByKey(rootState.stats.statData, state.groupKey, {
                        omitKey: true, childKey: state.childKey
                    })
                    // console.log('ACTIVIY MODULE ACTION', data)
                    let mutationData = rootGetters.setDataAndReady(data, true)
                    let counts = {};

                    commit("activityData", mutationData);
                    const countsByType = (key, set) => {
                        let count = { press: 0, scan: 0 };
                        set.forEach((element) => {
                            if (element.stat_type == "scan") {
                                count.scan += 1;
                            } else if (element.stat_type == "press") {
                                count.press += 1;
                            }
                        });
                        return { [key]: count };
                    };
                    let activity = Object.keys(state.activityData)
                    activity.forEach((groupKey) => {
                        Object.assign(counts, countsByType(groupKey, state.activityData[groupKey]));
                    });
                    let hourLabels = getters.getHourLabels(0, 23)
                    commit('activityStatLabels', hourLabels)
                    commit('labeledDatasets', getters.getLabeledDatasets(hourLabels))
                    commit('activityCountStats', rootGetters.setDataAndReady(counts, true))
                    dispatch('getActivityDatasets')
                },
                getActivityDatasets({ state, commit, getters, rootGetters, rootState }) {
                    let arraysObj = {};
                    let statType = rootState.stats.selectedStatType;
                    let dataset = [];
                    arraysObj[statType] = [];
                    let objKeys = Object.keys(state.activityCountStats)
                    objKeys.forEach((key) => {
                        let dateKey = getters.sliceDatetimeStr(key)
                        state.labeledDatasets[dateKey].push(state.activityCountStats[key][statType])

                        arraysObj[statType].push(state.activityCountStats[key][statType])
                    });
                    let { backgroundColor, borderColor } = rootGetters.generateRGBAStyle();
                    let data = Object.keys(state.labeledDatasets).map((key) => {
                        if (state.labeledDatasets[key][0] != undefined)
                            return state.labeledDatasets[key][0]
                        else
                            return 0
                    });
                    dataset = [{
                        data: data,
                        backgroundColor: [backgroundColor],
                        borderColor: [borderColor],
                        borderWidth: 1,
                    }];
                    // console.log("SETS", dataset)

                    commit('activityDatasets', rootGetters.setDataAndReady(dataset, true))
                },

            },
            mutations: {
                activityData(state, { data, ready }) {
                    state.activityData = data;
                    state.activityDataReady = ready;
                },
                activityCountStats(state, { data, ready }) {
                    state.activityCountStats = data;
                    state.activityCountStatsReady = ready;
                },
                activityStatLabels(state, labels) {
                    state.activityStatLabels = labels;
                },
                activityDatasets(state, { data, ready }) {
                    state.activityDatasets = data
                    state.activityDatasetsReady = ready
                },
                labeledDatasets(state, data) {
                    state.labeledDatasets = data
                }
            },
            getters: {
                getHourLabels: () => (min, max) => {
                    let labels = []
                    for (; min < max; min++) {
                        let label = (min < 10) ? `0${min}:00` : `${min}:00`
                        labels.push(label)
                    }
                    return labels
                },
                getLabeledDatasets: () => (labels) => {
                    let labeledDatasets = {}

                    labels.forEach((label) => {
                        labeledDatasets[label] = []
                    })
                    return labeledDatasets
                },
                sliceDatetimeStr: () => (datetimeStr) => {
                    let slicedStr = datetimeStr.split('T')[1].replace('Z', '')
                    let timeStrArray = slicedStr.split(':')
                    return timeStrArray[0] + ":" + timeStrArray[1]
                }
            }
        },
    }
};
