<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 position-relative z-index-2">
        <div class="row">
          <div class="col-12" style="margin-left: 10px">
            <!--            <p v-if="noStats">-->
            <!--              Aucune statistiques selon les paramètres de recherches-->
            <!--              sélectionnées-->
            <!--            </p>-->
            <p>
              Extraire toutes les données en CSV
              <button
                @click="this.downloadCSVRequest()"
                type="button"
                :class="['btn', 'btn-outline-success', 'mx-1']"
              >
                <i class="material-icons opacity-10">file_download</i> CSV
              </button>
            </p>
          </div>
        </div>
        <div class="col-12">
          <div class="card my-4">
            <div class="p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <table>
                  <th>
                    <div
                      style="
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                      "
                    >
                      <h6 class="text-white ps-3">
                        <i class="fas fa-filter"></i> Sélectionner vos filtres
                        de recherche
                      </h6>

                      <!--                      <button-->
                      <!--                          @click="this.downloadCSVRequest()"-->
                      <!--                          type="button"-->
                      <!--                          class="btn btn-outline-success text-white text-capitalize position-absolute top-50 end-13 translate-middle"-->
                      <!--                      >-->
                      <!--                        <i class="material-icons opacity-10">file_download</i> CSV-->
                      <!--                      </button>-->
                      <button
                        @click="this.filterRequest()"
                        type="button"
                        class="mb-0 btn bg-gradient-light js-btn-prev position-absolute top-50 end-0 translate-middle"
                      >
                        <i class="material-icons opacity-10">search</i>
                        Appliquer le filtre
                      </button>
                    </div>
                  </th>
                </table>
              </div>
            </div>
            <div class="card-body px-0 pb-2 m-3 pt-0">
              <div class="table-responsive p-0">
                <div class="row">
                  <div class="mt-3 mb-3 col-lg-3 col-md-6">
                    <stat-date-picker />
                  </div>
                  <div
                    v-if="statStoreListReady"
                    class="mt-3 mb-3 col-lg-3 col-md-6"
                  >
                    <stat-store-list title="Votre magasin :" />
                  </div>
                  <div
                    v-if="statDeviceListReady"
                    class="mt-3 mb-3 col-lg-3 col-md-6"
                  >
                    <stat-device-list title="Votre borne :" />
                  </div>
                  <div
                    v-if="statStoreListReady"
                    class="mt-3 mb-3 col-lg-3 col-md-6"
                  >
                    <stat-type-list title="Type :" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-sm-6 col-lg-7 col-md-12"></div>
          <div class="col-sm-6 col-lg-8 col-md-8 mt-lg-0 mt-md-4">
            <chart
              v-if="productDatasetsReady"
              id="chart-product"
              title="Répartition par produit"
              type="bar"
              :datasets="productDatasets"
              :statType="selectedStatType"
              :labels="productStatLabels"
              :options="productChartOptions"
            />
          </div>
          <div class="col-sm-6 col-lg-4 col-md-4 mt-lg-0 mt-md-4">
            <chart
              v-if="categoryDatasetsReady"
              id="chart-categories"
              title="Répartition par catégorie"
              type="pie"
              :datasets="categoryDatasets"
              :statType="selectedStatType"
              :labels="categoryStatLabels"
              :options="categoryChartOptions"
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-sm-6 col-lg-12 col-md-12 mt-lg-0 mt-md-4">
            <chart
              v-if="categoryDatasetsReady"
              id="chart-activity"
              title="Répartition par heure"
              type="line"
              :datasets="activityDatasets"
              :statType="selectedStatType"
              :labels="activityStatLabels"
              :options="activityChartOptions"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import axios from "axios";
import FileDownload from "js-file-download";
import Chart from "./components/Chart.vue";
import StatDatePicker from "./components/stats/StatDatePicker.vue";
import StatStoreList from "./components/stats/StatStoreList.vue";
import StatDeviceList from "./components/stats/StatDeviceList.vue";
import StatTypeList from "./components/stats/StatTypeList.vue";
export default {
  setup() {
    return {
      token: "",
    };
  },
  computed: {
    ...mapGetters("stats", ["queryFilters"]),
    ...mapState(["backendUrl"]),
    ...mapState("stats", [
      "noStats",
      "statDataReady",
      "statStoreListReady",
      "statDeviceListReady",
      "selectedDateRange",
      "selectedStore",
      "selectedDevice",
      "selectedStatType",
    ]),
    ...mapState("stats/products", {
      productStatLabels: (state) => state.productStatLabels,
      productDatasets: (state) => state.productDatasets,
      productDatasetsReady: (state) => state.productDatasetsReady,
      productChartOptions: (state) => state.productChartOptions,
    }),
    ...mapState("stats/categories", {
      categoryStatLabels: (state) => state.categoryStatLabels,
      categoryDatasets: (state) => state.categoryDatasets,
      categoryDatasetsReady: (state) => state.categoryDatasetsReady,
      categoryChartOptions: (state) => state.categoryChartOptions,
    }),
    ...mapState("stats/activity", {
      activityStatLabels: (state) => state.activityStatLabels,
      activityDatasets: (state) => state.activityDatasets,
      activityDatasetsReady: (state) => state.activityDatasetsReady,
      activityChartOptions: (state) => state.activityChartOptions,
    }),
  },
  mounted() {
    this.token = JSON.parse(localStorage.user).token;
    this.$store.dispatch("stats/initStatStoreList").then(() => {
      this.generateFilterQueryParams();
      this.$store.dispatch("stats/initStatData");
    });
    // console.log("STATS", this.$store.state);
    // console.log("PRODUCT STORE", this.productData);
    // console.log("SETS IN STATS", this.productDatasets);
  },
  name: "statistics",
  components: {
    Chart,
    StatDatePicker,
    StatStoreList,
    StatDeviceList,
    StatTypeList,
    // ChartLineTasks,
  },
  methods: {
    ...mapActions("module/stats", ["initStatStoreList"]),

    filterRequest() {
      // console.log("FIlter request");
      this.generateFilterQueryParams();
      this.$store.dispatch("stats/updateStats");
    },
    generateFilterQueryParams() {
      // let queryParams = {};

      // this.selectedStatType && (queryParams.type = this.selectedStatType);
      // this.selectedStore && (queryParams.store = this.selectedStore.id);

      // this.selectedDateRange.start &&
      // this.selectedDateRange.end &&
      // this.selectedDateRange.start == this.selectedDateRange.end
      //   ? (queryParams.date_after = this.selectedDateRange.start + "+00:00")
      //   : (queryParams.date_after = this.selectedDateRange.start + "+00:00") &&
      //     (queryParams.date_before = this.selectedDateRange.end + "+23:59");
      // this.selectedDevice && (queryParams.device = this.selectedDevice.id);
      let queryParams = ``;
      queryParams += this.selectedStore ? `store=${this.selectedStore.id}` : "";
      queryParams += this.selectedStatType
        ? `&type=${this.selectedStatType}`
        : "";
      queryParams +=
        this.selectedDateRange.start &&
        this.selectedDateRange.end &&
        this.selectedDateRange.start == this.selectedDateRange.end
          ? `&date_after=${this.selectedDateRange.start}+00:00`
          : `&date_after=${this.selectedDateRange.start}+00:00&date_before=${this.selectedDateRange.end}+23:59`;
      // console.log("inside gen qparams", this.selectedDevice != null);
      queryParams +=
        this.selectedDevice != null ? `&device=${this.selectedDevice.id}` : ``;
      // console.log("params", queryParams);
      this.$store.commit("stats/setQueryParams", queryParams);
    },
    downloadCSVRequest() {
      let fetchUrl = this.backendUrl + "stats/export";
      var Axiosheader = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + this.token,
        },
      };
      axios
        .get(fetchUrl, Axiosheader)
        .then((response) => {
          FileDownload(response.data, "export.csv");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style>
#chart-product {
  height: 50%;
}
#chart-categories {
  height: 50%;
}

.custom-wrap {
  flex: 1;
}

.custom-container {
  box-sizing: border-box;

  /* border: 1px solid;
  border-color: #cbd5e0;
  border-radius: 0.5rem; */
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  max-height: 19rem;
}
.custom-container.scrollable {
  overflow: auto;
}

ul.clickable-list {
  list-style-type: none;
  padding: 0;
  margin: 5px;
  overflow: auto;
}
.clickable-list li {
  border: 1px solid;
  border-color: #cbd5e0;
  border-radius: 0.5rem;
  margin: 2px 5px;
  padding: 5px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.clickable-list li:hover {
  color: #67b35f;
  font-size: 17px;
  -webkit-transition: all 0.5s ease-in;
  -moz-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  -ms-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
}

.clickable-list li.selected {
  color: #67b35f;
  font-size: 17px;
}

.custom-card-header {
  padding: 1rem / 2;
  margin-bottom: 0;
  /* color: $card-cap-color; */
  background-color: #fff;
  border-bottom: 0 solid rgba("black", 0.125);
  height: 100%;

  /* &:first-child {
    @include border-radius($card-inner-border-radius $card-inner-border-radius 0 0);
  }*/
}

button[id^="btn-"] {
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

button[id^="btn-"]:hover {
  background-color: #67b35f;
  color: #fff;
  -webkit-transition: all 0.5s ease-in;
  -moz-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  -ms-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
}
</style>
